import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './components/error404/error404.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SessionOutComponent } from './components/session-out/session-out.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  // { path: 'login', component: HomeComponent }
  { path: 'home', component: HomeComponent },
  { path: 'error', component: Error404Component },
  { path: 'authError', component: UnauthorizedComponent },
  { path: 'sessionTimeout', component: SessionOutComponent }



];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
