import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BaseUrl} from '../baseUrl';
@Injectable({
  providedIn: 'root',
})
export class DevicepersonaService {

  devicePersona:any={
    "assetDetails":null,
    "asset":null,
    "AssestADdetails":null,
    
  }

  constructor(private http: HttpClient) {}


  public getDeviceInfo(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'DevicedetailbySysid',
      payload: {
        sysid: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }


  
  public assetRefreshDate(AssetSysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'AssetRefreshDate',
      payload: {
        assetSysId: AssetSysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }

  public OwnedDevice(email: any) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      "action": "getUserownedDevices",
      "payload": {
          "email":email
      }
  };

    return this.http.post(BaseUrl + 'esi/ad', data, options);
  }

  public getAssetRefreshDate(assetSysId: any) {      
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      "action": "AssetRefreshDate",
      "payload": {
          "assetSysId":assetSysId
      }
  };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }


  public Installedsoftware(assetSysId: any) {      
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      "action": "Softwareinstalled",
      "payload": {
          "sys_id":assetSysId
      }
  };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }

  public ResetDevicePersona(){
    this.devicePersona={
    "assetDetails":null,
    "asset":null,
    "AssestADdetails":null
  }
  }
}
