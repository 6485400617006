import { DevicepersonaService } from './../../../service/device/devicepersona.service';
import { UserpersonaService } from 'src/app/service/user/userpersona.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AfterViewInit, Component, ViewChild,OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface SoftwareData {

  display_name: any;
  publisher: any;
  version: any;
  install_date:any;
  last_scanned:any
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit {

  displayedColumns: string[] = [ 'display_name', 'publisher', 'version',"install_date","last_scanned"];
  dataSource!: MatTableDataSource<SoftwareData>;

  @ViewChild(MatPaginator) paginator :any =MatPaginator;
  @ViewChild(MatSort) sort:any = MatSort;


  notAvailableMsg = '-';
  asset: any;
  sys_id: any;
  assetDetails: any;
  ownedDeviceDetailsofUser: any = [];
  SelectedDeviceLabel: any = 'Select Asset';
  CopyText: string = 'Not available';
  CurrentAssetRefreshDate: any = '-';
  softwareInstalled:any=[];
  constructor(
    private _userpersona: UserpersonaService,
    private _devicepersona: DevicepersonaService
  ) {

  
  }

  ngOnInit(): void {
    // console.log('deviceWorks');
    this.sys_id = this._userpersona.globalUserid;
    try {
      if (this._devicepersona.devicePersona.assetDetails != null) {
        // console.log(JSON.stringify(this._devicepersona.devicePersona));
        this.assetDetails = this._devicepersona.devicePersona.assetDetails;
        this.asset = this._devicepersona.devicePersona.asset;
        this.getAssetRefreshDAte(this.asset['asset.sys_id']);
        this.getSoftwareInstalledStatus(this.asset["sys_id"]);
       
        this.SelectedDeviceLabel = this.asset.name;
         this.handleAssetDetailsCopy(this.asset);
      } else {
        this._devicepersona
          .getDeviceInfo(this.sys_id)
          .subscribe((response: any) => {
            //   console.log(response)
            this.assetDetails = response.data;
            //saved asset detials globally

            if (response.data.length > 0) {
              this._devicepersona.devicePersona.assetDetails =
                this.assetDetails;
              this.asset = response.data[0];
              this.getAssetRefreshDAte(this.asset['asset.sys_id']);
              this.getSoftwareInstalledStatus(this.asset["sys_id"]);

              //saved asset globally
              this._devicepersona.devicePersona.asset = this.asset;

              this.SelectedDeviceLabel = response.data[0].name;
              this.handleAssetDetailsCopy(this.asset);
              // console.log(this.asset);
            } else {
              this.SelectedDeviceLabel = 'No Asset Found';
            }
          });
      }

      if (this._devicepersona.devicePersona.AssestADdetails != null) {
        // console.log(this._devicepersona.devicePersona.AssestADdetails);

        this.ownedDeviceDetailsofUser =
          this._devicepersona.devicePersona.AssestADdetails;
         
      } else {
        this.ownedDeviceDetails();
      }
    } catch (e) {
      console.log('e', e);

      console.log('catch error');
    }
  }

  handleAssetDetailsCopy(data: any) {
    let d = `
  Asset Name : ${data?.name ? data?.name : this.notAvailableMsg} 
  Asset Status : ${
    data?.install_status ? data?.install_status : this.notAvailableMsg
  }
  Model : ${data?.model_id ? data?.model_id : this.notAvailableMsg}
  Serial Number :  ${
    data?.serial_number ? data?.serial_number : this.notAvailableMsg
  }
  Manufacturer :  ${
    data?.manufacturer ? data?.manufacturer : this.notAvailableMsg
  }
  Form Factor :  ${data?.form_factor ? data?.form_factor : this.notAvailableMsg}
  KC Build Type :  ${
    data?.u_kc_build_type ? data?.u_kc_build_type : this.notAvailableMsg
  }
  Assigned To :  ${data?.assigned_to ? data?.assigned_to : this.notAvailableMsg}
  Last Communicated Date :  ${
    data?.u_last_communicated_date
      ? data?.u_last_communicated_date
      : this.notAvailableMsg
  }
  Asset Refresh Date :  ${data?.name ? data?.name : this.notAvailableMsg}
  Asset Warranty Expiration Date :  ${
    data?.warranty_expiration ? data?.warranty_expiration : this.notAvailableMsg
  }
  Support Group :  ${
    data?.support_group ? data?.support_group : this.notAvailableMsg
  }
  Last Logged On User :  ${
    data?.u_last_logged_on_user
      ? data?.u_last_logged_on_user
      : this.notAvailableMsg
  }
  Last Authenticated Location :  ${
    data?.u_last_authenticated_location
      ? data?.u_last_authenticated_location
      : this.notAvailableMsg
  }
  Asset Created On :  ${
    data?.sys_created_on ? data?.sys_created_on : this.notAvailableMsg
  }
  Last SCCM Feed Date :  ${
    data?.u_last_sccm_feed_date
      ? data?.u_last_sccm_feed_date
      : this.notAvailableMsg
  }
  `;
    this.CopyText = d;
  }

  selectOption(value: any) {
    console.log('inside device', this.asset);
    console.log('inside device', this.asset["sys_id"]);


    this.asset = value;
    this.getAssetRefreshDAte(this.asset['asset.sys_id']);
    this.getSoftwareInstalledStatus(this.asset["sys_id"]);

    this.handleAssetDetailsCopy(value);
    this.SelectedDeviceLabel = 'Selected Asset';
  }

  ownedDeviceDetails() {
    this._devicepersona.devicePersona.AssestADdetails = [];
    this._devicepersona
      .OwnedDevice(this._userpersona.globalUserEmail)
      .subscribe((response: any) => {
        this.ownedDeviceDetailsofUser = response.data.value;
        this._devicepersona.devicePersona.AssestADdetails =
          this.ownedDeviceDetailsofUser;

        //  console.log(JSON.stringify(this.ownedDeviceDetailsofUser))
      });
  }

  getAssetRefreshDAte(Asset_id: any) {
    this._devicepersona.getAssetRefreshDate(Asset_id).subscribe(
      (res: any) => {
        if (res.http_status_code === 200) {         
          this.CurrentAssetRefreshDate = res.data[0]['u_refresh_date'];
        } else {
          this.CurrentAssetRefreshDate = this.notAvailableMsg;
        }
      },
      (error) => {
        this.CurrentAssetRefreshDate = "unable to fetch";
        console.log('error', error);
      }
    );
  }

    ngAfterViewInit() {
   
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getSoftwareInstalledStatus(Asset_id: any){
    this._devicepersona.Installedsoftware(Asset_id).subscribe(
      (res: any) => {
        if (res.http_status_code === 200) {         
          this.softwareInstalled=res.data
          let users = res.data;
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
         console.log(res.data)
        } else {
          this.CurrentAssetRefreshDate = this.notAvailableMsg;
        }
      },
      (error) => {
        this.CurrentAssetRefreshDate = "unable to fetch";
        console.log('error', error);
      }
    );
  }
}
