import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { UserComponent } from './components/user/user/user.component';
import { DeviceComponent } from './components/device/device/device.component';
import { SupportComponent } from './components/support/support/support.component';
import { VadetailsComponent } from './components/vadetails/vadetails/vadetails.component';
import { HttpClientModule } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { OktaMfaComponent } from './components/okta-mfa/okta-mfa.component';
import { AppFilterPipe } from './app-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { Error404Component } from './components/error404/error404.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SessionOutComponent } from './components/session-out/session-out.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserComponent,
    DeviceComponent,
    SupportComponent,
    VadetailsComponent,
    OktaMfaComponent,
    AppFilterPipe,
    FavoritesComponent,
    Error404Component,
    UnauthorizedComponent,
    SessionOutComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    HttpClientModule,
    NgxPaginationModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
