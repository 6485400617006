import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BaseUrl} from '../baseUrl';
@Injectable({
  providedIn: 'root'
})
export class VapersonaService {

  constructor(private http: HttpClient) { }

    public getKBArticles(metaData: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action:"KBsearchbyMeta",
      payload:{
        meta:metaData
      }   
  };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
}
