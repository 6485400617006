<div class="pagealign">
  <div>
    <div class="row">
      <div class="col-3">
        <div class="mt-2">
          <mat-card class="card-background">
            <div class="flex" style="flex-direction: row; align-items: center">
              <i class="material-icons iconstyle" style="color: white"
                >library_books</i
              >
              <span class="ml-2 title-white" style="font-size: 16px"
                >Knowledge Base
              </span>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="m-1">
                  <p
                    (click)="
                      redirectToLink(
                        'https://kcc.service-now.com/kc_sp?id=kc_kb_home'
                      )
                    "
                    style="
                      cursor: pointer;
                      color: white;
                      border-bottom: 1px solid white;
                      margin: 0 25px 12px;
                    "
                  >
                    IT Knowledge Center
                  </p>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card class="card-background mt-2">
            <div class="flex" style="flex-direction: row; align-items: center">
              <i class="material-icons iconstyle" style="color: white"
                >local_phone</i
              >
              <span class="ml-2 title-white" style="font-size: 16px"
                >DESK Phone Numbers</span
              >
            </div>

            <div class="row mt-2">
              <div class="col">
                <div class="m-1">
                  <p
                    (click)="
                      redirectToLink(
                        'https://kcc.service-now.com/kc_sp/?id=desk_phones'
                      )
                    "
                    style="
                      cursor: pointer;
                      color: white;
                      border-bottom: 1px solid white;
                      margin: 0 25px 12px;
                    "
                  >
                    Phone Numbers
                  </p>
                </div>
              </div>
            </div>
          </mat-card>
          <div class="mt-2">
            <mat-card class="card-background">
              <div
                class="flex"
                style="flex-direction: row; align-items: center"
              >
                <i class="material-icons iconstyle" style="color: white"
                  >stars</i
                >
                <span class="ml-2 title-white" style="font-size: 15px">
                  Favorite Links
                </span>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <div *ngFor="let item of favoritesUrl" class="m-1">
                    <p
                      (click)="redirectToLink(item.Url)"
                      style="
                        cursor: pointer;
                        color: white;
                        border-bottom: 1px solid white;
                        margin: 0 25px 12px;
                      "
                    >
                      {{ item.Title }}
                    </p>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="col-9">
        <mat-card class="backgroundWhite mt-2">
          <div class="mt-2">
          
            <p style="font-weight: 500;display: flex;
            align-items: center;">  <mat-icon style="margin-right: 5px;">donut_small</mat-icon>User Interaction Trend (Last 90days)</p>
            <div
              style="height: 80%; width: 100%; text-align: center"
              *ngIf="!isChartsAvailable"
            >
              <p>No Data Found</p>
            </div>
            <div
              style="height: 80%; width: 80%; text-align: center"
              >
              <!-- *ngIf="isChartsAvailable" -->
              <canvas id="myChart"></canvas>
            </div>
            <hr />

            <div class="mt-3">
              <p style="font-weight: 500;display: flex;
              align-items: center;">
                <mat-icon  style="margin-right: 5px;">assessment</mat-icon>Customer satisfaction
              </p>
              <div
                style="height: 80%; width: 100%; text-align: center"
                *ngIf="!isChartsAvailable"
              >
                <p>No Data Found</p>
              </div>
              <div
                style="height: 80%; width: 80%; text-align: center"
                >
                <!-- *ngIf="isChartsAvailable" -->
                <canvas id="myChart5"></canvas>
              </div>
            </div>

            <div class="mt-3">
              <p style="font-weight: 500;display: flex;
              align-items: center;">
                <mat-icon  style="margin-right: 5px;">assessment</mat-icon> Top Configuration Items (Last 90days)
              </p>
              <div
                style="height: 80%; width: 100%; text-align: center"
                *ngIf="!isChartsAvailable"
              >
                <p>No Data Found</p>
              </div>
              <div
                style="height: 80%; width: 80%; text-align: center"
                >
                <!-- *ngIf="isChartsAvailable" -->
                <canvas id="myChart1"></canvas>
              </div>
            </div>
            <hr />

            <div class="mt-3">
              <p style="font-weight: 500;display: flex;
              align-items: center;">  <mat-icon  style="margin-right: 5px;">donut_small</mat-icon>Top Category Items (Last 90days)</p>
              <div
                style="height: 80%; width: 100%; text-align: center"
                *ngIf="!isChartsAvailable"
              >
                <p>No Data Found</p>
              </div>
              <div
                style="height: 80%; width: 80%; text-align: center"
                >
                <!-- *ngIf="isChartsAvailable" -->
                <canvas id="myChart2"></canvas>
              </div>
            </div>
            <hr />

            <div class="mt-3">
              <p style="font-weight: 500;display: flex;
              align-items: center;">
                <mat-icon  style="margin-right: 5px;">assessment</mat-icon> Top Sub-Category Items (Last 90days)
              </p>
              <div
                style="height: 80%; width: 100%; text-align: center"
                *ngIf="!isChartsAvailable"
              >
                <p>No Data Found</p>
              </div>
              <div
                style="height: 100%; width: 100%; text-align: center"
                >
                <!-- *ngIf="isChartsAvailable" -->
                <canvas id="myChart3"></canvas>
              </div>
            </div>          
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container" style="margin-top: 20px">
  <div class="row ng-scope">
    <div class="col-md-3 col-md-push-9">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel
          style="background-color: #10069f"
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          hideToggle
          [expanded]="panelOpenState"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div
                class="flex"
                style="flex-direction: row; align-items: center"
              >
                <i class="material-icons iconstyle" style="color: white"
                  >stars</i
                >
                <span class="ml-2" style="color: white"> Favorite Links </span>
              </div></mat-panel-title
            >
          </mat-expansion-panel-header>
          <div *ngFor="let item of favoritesUrl">
            <p
              (click)="redirectToLink(item.Url)"
              style="
                cursor: pointer;
                color: white;
                border-bottom: 1px solid white;
              "
            >
              {{ item.Title }}
            </p>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <div *ngFor="let item of favoritesUrl">
            <p
              (click)="redirectToLink(item.Url)"
              style="cursor: pointer; color: white"
            >
              {{ item.Title }}
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-md-9 col-md-pull-3" style="background-color: white">
      <div class="mt-2">content</div>
    </div>
  </div>
</div> -->
