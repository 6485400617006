import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { group } from 'console';
import { OktaService } from 'src/app/service/okta/okta.service';
import { UserpersonaService } from 'src/app/service/user/userpersona.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-okta-mfa',
  templateUrl: './okta-mfa.component.html',
  styleUrls: ['./okta-mfa.component.css'],
})
export class OktaMfaComponent implements OnInit {
  // Input Form Input -START
  SMSformInput = [
    'smsInput1',
    'smsInput2',
    'smsInput3',
    'smsInput4',
    'smsInput5',
    'smsInput6',
  ];
  PhoneformInput = [
    'phoneInput1',
    'phoneInput2',
    'phoneInput3',
    'phoneInput4',
    'phoneInput5',
  ];
  OktaMobformInput = [
    'oktaMobInput1',
    'oktaMobInput2',
    'oktaMobInput3',
    'oktaMobInput4',
    'oktaMobInput5',
    'oktaMobInput6',
  ];
  // Input Form Input -END

  @ViewChildren('SmsformRow') Smsrows: any;
  @ViewChildren('PhoneformRow') Phonerows: any;
  @ViewChildren('OktaMobformRow') OktaMobrows: any;

  mfaOptions: any = {
    Sms: {
      isSend: false,
      isVerified: false,
      title: 'sms',
      isloading: false,
      isEnabled: false,
      factorId: '',
    },
    PhoneCall: {
      isSend: false,
      isVerified: false,
      title: 'call',
      isloading: false,
      isEnabled: false,
      factorId: '',
    },
    OktaVerify: {
      isSend: false,
      isVerified: false,
      title: 'okta verify',
      isloading: false,
      isEnabled: false,
      push: {
        factorId: '',
        transactionId: '',
      },
      totp: {
        factorId: '',
      },
    },
    // TempPassword: {
    //   isSend: false,
    //   isGenerated: false,
    //   defaultValue: 'Click Generate',
    //   title: 'GenerateTempPassword',
    //   isloading: false,
    //   isEnabled: false,
    // },
  };

  SmsVal: Array<number> = [];
  PhoneCallVal: Array<number> = [];
  OktaVerifyVal: Array<number> = [];
  toggleClass: boolean = false;
  notAvailable: any = 'Not available';
  allFactors: any;
  phonenumber: any = '';
  isMFALoading: boolean = true;
  phonenumberWithspecialCharacter: any = '';
  constructor(
    private _userpersona: UserpersonaService,
    private _oktaService: OktaService,
    private _router: Router
  ) {}

  ngOnInit() {
    if (
      this._userpersona.oktaUserInfo &&
      this._userpersona.oktaUserInfo.profile.MFA != 'none'
    ) {
      if (Object.keys(this._oktaService.allFactors).length > 0) {
        this.phonenumber = this._oktaService.allFactors[0].profile?.phoneNumber;
        console.log("OktaMfaComponent ~ ngOnInit ~ this.phonenumber", this.phonenumber)
        if(this.phonenumber!=undefined){

          console.log('exisitng okta MFA details', this._oktaService.mfaOptions);
          let number=this.phonenumber.replace(/(\d{2})\d{6}(\d{3})/, '$1******$2')
          this.phonenumberWithspecialCharacter=number

        }
        
         // Enable OktaMob MFA - Push
         if (this._oktaService.mfaOptions.OktaVerify.isEnabled) {
          this.mfaOptions.OktaVerify.isEnabled = true;
          this.mfaOptions['OktaVerify'].push.factorId =
            this._oktaService.mfaOptions.OktaVerify.push.factorId;
          if (this._oktaService.mfaOptions.OktaVerify.isVerified) {
            this.mfaOptions['OktaVerify'].isSend = true;
            this.mfaOptions['OktaVerify'].isVerified = true;
          } else {
            this.mfaOptions['OktaVerify'].isSend = false;
            this.mfaOptions['OktaVerify'].isVerified = false;
          }
        }
        // console.log("3");

        // Enable OktaMob MFA - TOTP
        if (this._oktaService.mfaOptions.OktaVerify.isEnabled) {
          this.mfaOptions.OktaVerify.isEnabled = true;
          this.mfaOptions['OktaVerify'].totp.factorId =
            this._oktaService.mfaOptions.OktaVerify.totp.factorId;
          if (this._oktaService.mfaOptions.OktaVerify.isVerified) {
            this.mfaOptions['OktaVerify'].isVerified = true;
          } else {
            this.mfaOptions['OktaVerify'].isVerified = false;
          }
        }
        
        // Enable SMS MFA
        if (this._oktaService.mfaOptions.Sms.isEnabled) {
          this.mfaOptions.Sms.isEnabled = true;
          this.mfaOptions['Sms'].factorId =
            this._oktaService.mfaOptions.Sms.factorId;
          if (this._oktaService.mfaOptions.Sms.isVerified) {
            this.mfaOptions['Sms'].isVerified = true;
            this.mfaOptions['Sms'].isSend = true;
          } else {
            this.mfaOptions['Sms'].isVerified = false;
            this.mfaOptions['Sms'].isSend = false;
          }
        }
        // console.log("1");

        // Enable PhoneCall MFA
        if (this._oktaService.mfaOptions.PhoneCall.isEnabled) {
          this.mfaOptions.PhoneCall.isEnabled = true;
          this.mfaOptions['PhoneCall'].factorId =
            this._oktaService.mfaOptions.PhoneCall.factorId;
          if (this._oktaService.mfaOptions.PhoneCall.isVerified) {
            this.mfaOptions['PhoneCall'].isVerified = true;
            this.mfaOptions['PhoneCall'].isSend = true;
          } else {
            this.mfaOptions['PhoneCall'].isSend = false;
            this.mfaOptions['PhoneCall'].isVerified = false;
          }
        }
        // console.log("2");

       
        // console.log("4");
        // Enable Temp password
        // this.mfaOptions.TempPassword.isEnabled = false;
        this.isMFALoading = false;
      } else {
        // Get User List of Active MFA
        this._oktaService
          .getUserMFA(
            this._userpersona.oktaUserInfo.id,
            this._userpersona.globalOktaAccessToken
          )
          .subscribe(
            (MfaDetails: any) => {
              if (MfaDetails?.http_status_code == 200) {
                if (MfaDetails.data.length) {
                  this.allFactors = MfaDetails.data;
                  this._oktaService.allFactors = MfaDetails.data;
                  console.log('MfaDetails-User Info', this.allFactors);
                  // console.log(this.allFactors.profile.phoneNumber)

                  
                  this.phonenumber = this.allFactors[0].profile.phoneNumber;
                  if(this.phonenumber!=undefined){

                    let number=this.phonenumber.replace(/(\d{2})\d{6}(\d{3})/, '$1******$2')
                    this.phonenumberWithspecialCharacter=number
                  }
                 
                  for (let i of this.allFactors) {
                    if (i.factorType === 'sms') {
                      this.mfaOptions.Sms.isEnabled = true;
                      this.mfaOptions.Sms.factorId = i.id;
                      this._oktaService.mfaOptions.Sms.isEnabled = true;
                      this._oktaService.mfaOptions.Sms.factorId = i.id;
                      console.log(i);
                    }
                    if (i.factorType === 'call') {
                      this.mfaOptions.PhoneCall.isEnabled = true;
                      this.mfaOptions.PhoneCall.factorId = i.id;
                      this._oktaService.mfaOptions.PhoneCall.isEnabled = true;
                      this._oktaService.mfaOptions.PhoneCall.factorId = i.id;

                      console.log(i);
                    }
                    if (i.factorType === 'push') {
                      this.mfaOptions.OktaVerify.isEnabled = true;
                      this.mfaOptions.OktaVerify.push.factorId = i.id;
                      this._oktaService.mfaOptions.OktaVerify.isEnabled = true;
                      this._oktaService.mfaOptions.OktaVerify.push.factorId =
                        i.id;

                      console.log(i);
                    }
                    if (i.factorType === 'token:software:totp') {
                      this.mfaOptions.OktaVerify.isEnabled = true;
                      this.mfaOptions.OktaVerify.totp.factorId = i.id;
                      this._oktaService.mfaOptions.OktaVerify.isEnabled = true;
                      this._oktaService.mfaOptions.OktaVerify.totp.factorId =
                        i.id;

                      console.log(i);
                    }
                  }

                  // this.mfaOptions.TempPassword.isEnabled = false;
                  this.isMFALoading = false;
                }
              }
              if (MfaDetails?.http_status_code == 401) {
                // this.clearUserSession();
                this._router.navigate(['/', 'sessionTimeout']);
              }
              this.isMFALoading = false;
            },
            (error) => {
              this.isMFALoading = false;
              console.log('error', error);
            }
          );
      }
    } else {
      this.isMFALoading = false;
    }
  }
  // Key event from input fields
  keyUpEvent(mfaType: string, event: any, index: number) {
    // console.log(mfaType, event.target.value, index);
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (mfaType === 'phone' && pos > -1 && pos < this.PhoneformInput.length) {
      this.Phonerows._results[pos].nativeElement.focus();
    }
    if (mfaType === 'sms' && pos > -1 && pos < this.SMSformInput.length) {
      this.Smsrows._results[pos].nativeElement.focus();
    }
    if (
      mfaType === 'oktamobverify' &&
      pos > -1 &&
      pos < this.OktaMobformInput.length
    ) {
      this.OktaMobrows._results[pos].nativeElement.focus();
    }
  }

  // Verify MFAs
  OnVerify(mfaType: string) {
    if (mfaType === 'sms') {
      if (this.SmsVal.length === 6) {
        console.log(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.Sms.factorId
        );
        this._oktaService
          .verifyFactorbyFactorid(
            this._userpersona.oktaUserInfo.id,
            this.mfaOptions.Sms.factorId,
            this.SmsVal.join(''),
            this._userpersona.globalOktaAccessToken
          )
          .subscribe(
            (MfaDetails: any) => {
              console.log(MfaDetails.data.factorResult);
              if (MfaDetails?.http_status_code == 200) {
                if (MfaDetails?.data?.factorResult == 'SUCCESS') {
                  Swal.fire({
                    text: 'OTP Verified Successfully',
                    icon: 'success',
                  });
                  this._oktaService.mfaOptions['Sms'].isVerified = true;
                  this.mfaOptions['Sms'].isVerified = true;
                } else {
                  Swal.fire({
                    text: 'Otp Verification failed',
                    icon: 'error',
                  });
                }
              }
              if (MfaDetails?.http_status_code == 403) {
                Swal.fire({
                  text: 'Invalid Passcode',
                  icon: 'error',
                });
              }
              if (MfaDetails?.http_status_code == 401) {
                // this.clearUserSession();
                this._router.navigate(['/', 'sessionTimeout']);
              }
              this.resetInputs('sms');
            },
            (error) => {
              Swal.fire({
                text: 'Otp Verification failed',
                icon: 'error',
              });
              console.log('error', error);
            }
          );
      } else {
        Swal.fire({
          text: 'Enter PassCode before verify',
          icon: 'error',
        });
      }
    } else if (mfaType === 'call') {
      if (this.PhoneCallVal.length === 5) {
        console.log(this.PhoneCallVal.join(''));
        console.log(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.PhoneCall.factorId
        );
        this._oktaService
          .verifyFactorbyFactorid(
            this._userpersona.oktaUserInfo.id,
            this.mfaOptions.PhoneCall.factorId,
            this.PhoneCallVal.join(''),
            this._userpersona.globalOktaAccessToken
          )
          .subscribe(
            (MfaDetails: any) => {
              console.log(MfaDetails.data.factorResult);
              if (MfaDetails?.http_status_code == 200) {
                if (MfaDetails.data.factorResult == 'SUCCESS') {
                  Swal.fire({
                    text: 'Otp Verified Successfully',
                    icon: 'success',
                  });
                  this._oktaService.mfaOptions['PhoneCall'].isVerified = true;
                  this.mfaOptions['PhoneCall'].isVerified = true;
                } else {
                  Swal.fire({
                    text: 'Otp Verification failed',
                    icon: 'error',
                  });
                }
              }
              if (MfaDetails?.http_status_code == 403) {
                Swal.fire({
                  text: 'Invalid Passcode',
                  icon: 'error',
                });
              }
              if (MfaDetails?.http_status_code == 401) {
                // this.clearUserSession();
                this._router.navigate(['/', 'sessionTimeout']);
              }
            },
            (error) => {
              Swal.fire({
                text: 'Otp Verification failed',
                icon: 'error',
              });
              console.log('error', error);
            }
          );
      } else {
        Swal.fire({
          text: 'Enter PassCode before verify',
          icon: 'error',
        });
      }
    } else if (mfaType === 'oktaMobPush') {
      console.log(this.OktaVerifyVal.join(''));
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.OktaVerify.push.factorId
      );
      this._oktaService
        .verifyFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.oktamobverify.push.factorId,
          this.OktaVerifyVal.join(''),
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (MfaDetails?.http_status_code == 200) {
              if (MfaDetails.data.factorResult == 'SUCCESS') {
                Swal.fire({
                  text: 'Otp verified Successfully',
                  icon: 'success',
                });
                this._oktaService.mfaOptions['oktamobverify'].isVerified = true;
                this.mfaOptions['oktamobverify'].isVerified = true;
              } else {
                Swal.fire({
                  text: 'Otp Verification failed',
                  icon: 'error',
                });
              }
            }
            if (MfaDetails?.http_status_code == 403) {
              Swal.fire({
                text: 'Invalid Passcode',
                icon: 'error',
              });
            }
            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
            this.resetInputs('call');
          },
          (error) => {
            Swal.fire({
              text: 'Otp Verification failed',
              icon: 'error',
            });
            console.log('error', error);
          }
        );
    } else if (mfaType === 'oktaMobTotp') {
      console.log('oktaMobTotp');
      if (this.OktaVerifyVal.length === 6) {
        console.log(this.OktaVerifyVal.join(''));
        console.log(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.OktaVerify.totp.factorId
        );
        this._oktaService
          .verifyFactorbyFactorid(
            this._userpersona.oktaUserInfo.id,
            this.mfaOptions.OktaVerify.totp.factorId,
            this.OktaVerifyVal.join(''),
            this._userpersona.globalOktaAccessToken
          )
          .subscribe(
            (MfaDetails: any) => {
              console.log(MfaDetails);
              if (MfaDetails?.http_status_code == 200) {
                if (MfaDetails.data.factorResult == 'SUCCESS') {
                  Swal.fire({
                    text: 'Verified Successfully',
                    icon: 'success',
                  });
                  this._oktaService.mfaOptions['OktaVerify'].isVerified = true;
                  this.mfaOptions['OktaVerify'].isVerified = true;
                } else {
                  Swal.fire({
                    text: 'Wrong TOTP Retry again !',
                    icon: 'error',
                  });
                }
              }
              if (MfaDetails?.http_status_code == 403) {
                Swal.fire({
                  text: 'Invalid Passcode',
                  icon: 'error',
                });
              }
              if (MfaDetails?.http_status_code == 401) {
                // this.clearUserSession();
                this._router.navigate(['/', 'sessionTimeout']);
              }
              this.resetInputs('oktaMobTotp');
            },
            (error) => {
              console.log('error', error);
            }
          );
      } else {
        Swal.fire({
          text: 'Enter PassCode before verify',
          icon: 'error',
        });
      }
    }
  }

  // Generate Temporary Password
  // generateTempPassword() {
  //   console.log('generate temp password');
  //   Swal.fire({
  //     // title: '',
  //     text: 'Are you sure want to create temporary password ?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.mfaOptions.TempPassword.defaultValue = 'XfEfg123j4n';
  //       // this._oktaService
  //       //   .TriggerTemporaryPassword(this._userpersona.oktaUserInfo.id)
  //       //   .subscribe(
  //       //     (tempDetails: any) => {
  //       //       console.log('tempDetails', tempDetails);
  //       //       if (
  //       //         tempDetails.http_status_code === 200 &&
  //       //         tempDetails.data.tempPassword
  //       //       ) {
  //       Swal.fire({
  //         text: 'Temporary Password Generated',
  //         icon: 'success',
  //       });
  //       //         this.mfaOptions.TempPassword.defaultValue =
  //       //           tempDetails.data.tempPassword;
  //       //       } else {
  //       //         this.mfaOptions.TempPassword.defaultValue =
  //       //           'Failed to generate';
  //       //       }
  //       //     },
  //       //     (error) => {
  //       //       console.log('error', error);
  //       //     }
  //       //   );
  //     }
  //   });
  // }

  // Handle Password Copy
  handleTemporaryPasswordCopy() {
    Swal.fire({
      width: '10%',
      position: 'top-end',
      title: 'Copied',
      showConfirmButton: false,
      timer: 1200,
    });
  }

  // Send OTP
  sendOTP(content: any) {
    console.log('send', content);
    if (content.mfaType === 'sms') {
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.Sms.factorId
      );
      this._oktaService
        .sendFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.Sms.factorId,
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (MfaDetails?.http_status_code == 200) {
              if (MfaDetails.data.factorResult) {
                Swal.fire({
                  text: 'OTP Sent Successfully',
                  icon: 'info',
                });
                this.mfaOptions['Sms'].isSend = true;
                this._oktaService.mfaOptions.Sms.isSend = true;
              }
            }
            if (MfaDetails?.http_status_code == 429) {
              Swal.fire({
                text: 'An SMS message was recently sent. Please wait 30 seconds before trying again.',
                icon: 'info',
              });
            }
            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    } else if (content.mfaType === 'call') {
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.PhoneCall.factorId
      );

      this._oktaService
        .sendFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.PhoneCall.factorId,
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (MfaDetails?.http_status_code == 200) {
              if (MfaDetails.data.factorResult) {
                Swal.fire({
                  text: 'Phonecall Sent Successfully',
                  icon: 'info',
                });

                this.mfaOptions['PhoneCall'].isSend = true;
                this._oktaService.mfaOptions.PhoneCall.isSend = true;
              }
            }
            if (MfaDetails?.http_status_code == 429) {
              Swal.fire({
                text: 'A phone call was recently made. Please wait 30 seconds before trying again.',
                icon: 'info',
              });
            }
            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    } else if (content.mfaType === 'oktaMobPush') {
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.OktaVerify.push.factorId
      );
      this._oktaService
        .sendFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.OktaVerify.push.factorId,
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (
              MfaDetails?.http_status_code == 200 ||
              MfaDetails?.http_status_code == 201
            ) {
              if (MfaDetails.data.factorResult) {
                Swal.fire({
                  text: 'Okta Push Notification Sent Successfully',
                  icon: 'info',
                });

                this.mfaOptions['OktaVerify'].isSend = true;
                this._oktaService.mfaOptions.OktaVerify.isSend = true;
                this.mfaOptions.OktaVerify.push.transactionId =
                  MfaDetails.data._links.poll.href.split('/transactions/')[1];
              }
            }

            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    }
  }

  // Validate Push notification - okta mobile push
  validatePushNotification() {
    console.log('validating push notification');
    this._oktaService
      .validateOktaMobilePush(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.OktaVerify.push.factorId,
        this.mfaOptions.OktaVerify.push.transactionId,
        this._userpersona.globalOktaAccessToken
      )
      .subscribe(
        (MfaDetails: any) => {
          console.log(MfaDetails);
          if (MfaDetails?.http_status_code == 200) {
            if (MfaDetails?.data?.factorResult === 'SUCCESS') {
              Swal.fire({
                text: 'Okta push notification verified Successfully',
                icon: 'success',
              });
              this.mfaOptions['OktaVerify'].isVerified = true;
              this._oktaService.mfaOptions.OktaVerify.isVerified = true;
            }
            if (MfaDetails?.data?.factorResult === 'WAITING') {
              Swal.fire({
                text: 'Okta push notification Waiting for update',
                icon: 'info',
              });
            }
            if (MfaDetails?.data?.factorResult === 'REJECTED') {
              Swal.fire({
                text: 'Okta push notification Rejected',
                icon: 'error',
              });
              this.mfaOptions['OktaVerify'].isSend = false;
              this._oktaService.mfaOptions['OktaVerify'].isSend = false;
            }
          }
          if (MfaDetails?.http_status_code == 401) {
            // this.clearUserSession();
            this._router.navigate(['/', 'sessionTimeout']);
          }
        },
        (error) => {
          console.log('error', error);
          Swal.fire({
            text: 'Error while verifying',
            icon: 'error',
          });
        }
      );
  }

  // Resend OTP
  resendOTP(content: any) {
    console.log('send', content);

    if (content.mfaType === 'sms') {
      this.mfaOptions['Sms'].isSend = true;
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.Sms.factorId
      );
      this._oktaService
        .sendFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.Sms.factorId,
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (MfaDetails?.http_status_code == 200) {
              if (MfaDetails.data.factorResult) {
                Swal.fire({
                  text: 'Otp Sent Successfully',
                  icon: 'info',
                });
                this.mfaOptions['Sms'].isSend = true;
                this._oktaService.mfaOptions.Sms.isSend = true;
              }
            }
            if (MfaDetails?.http_status_code == 429) {
              Swal.fire({
                text: 'An SMS message was recently sent. Please wait 30 seconds before trying again.',
                icon: 'info',
              });
            }
            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    }
    if (content.mfaType === 'call') {
      console.log(
        this._userpersona.oktaUserInfo.id,
        this.mfaOptions.PhoneCall.factorId
      );

      this._oktaService
        .sendFactorbyFactorid(
          this._userpersona.oktaUserInfo.id,
          this.mfaOptions.PhoneCall.factorId,
          this._userpersona.globalOktaAccessToken
        )
        .subscribe(
          (MfaDetails: any) => {
            console.log(MfaDetails.data.factorResult);
            if (MfaDetails?.http_status_code == 200) {
              if (MfaDetails.data.factorResult) {
                Swal.fire({
                  text: 'Phonecall Send Successfully',
                  icon: 'info',
                });

                this.mfaOptions['PhoneCall'].isSend = true;
                this._oktaService.mfaOptions.PhoneCall.isSend = true;
              }
            }
            if (MfaDetails?.http_status_code == 429) {
              Swal.fire({
                text: 'A phone call was recently made. Please wait 30 seconds before trying again.',
                icon: 'info',
              });
            }
            if (MfaDetails?.http_status_code == 401) {
              // this.clearUserSession();
              this._router.navigate(['/', 'sessionTimeout']);
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    }
  }

  // Handle Change Input values
  changeInputValues(t: string, val: any, index: number, e: any) {
    console.log(val, index, e.target.value);
    if (t === 'Sms') {
      this.SmsVal.splice(index, index + 1, e.target.value);
    }
    if (t === 'Phone') {
      this.PhoneCallVal.splice(index, index + 1, e.target.value);
    }
    if (t === 'OktaVerify') {
      this.OktaVerifyVal.splice(index, index + 1, e.target.value);
    }
  }

  resetInputs(t: string) {
    console.log(t);
    if (t === 'sms') {
      for (let index = 0; index < this.SMSformInput.length; index++) {
        this.Smsrows._results[index].nativeElement.value = '';
      }
      this.SmsVal = [];
    }
    if (t === 'call') {
      for (let index = 0; index < this.PhoneformInput.length; index++) {
        this.Phonerows._results[index].nativeElement.value = '';
      }
      this.PhoneCallVal = [];
    }

    if (t === 'oktaMobTotp') {
      for (let index = 0; index < this.OktaMobformInput.length; index++) {
        this.OktaMobrows._results[index].nativeElement.value = '';
      }
      this.OktaVerifyVal = [];
    }
  }
}
