import { MatDialog } from '@angular/material/dialog';
import Fuse from 'fuse.js';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { SupportpersonaService } from 'src/app/service/support/supportpersona.service';
import { UserpersonaService } from 'src/app/service/user/userpersona.service';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface IssueOption {
  value: string;
  name: string;
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  sys_id: any;
  ticketDatas: any;
  DeviceDatas: any;
  modalContent: any;
  serviceRequestDatas: any;
  RequestItemDatas: any;
  TaskDatas: any;
  WorkNotesModalContent: any;
  FixDeviceModalContent: any;
  AlertmodalContent: any;
  ServiceRequestModalContent: any;
  RequestItemModalContent: any;
  TaskModalContent: any;
  panelOpenState = false;
  searchTicketText: any = undefined;
  serviceRequestNumber: any = undefined;
  requestItemNumber: any = undefined;
  taskNumber: any = undefined;
  notAvailableMsg = '-';
  allTicketData: any;
  allServiceRequestData: any;
  allRequestItemData: any;
  allTaskDatas: any;
  IncidentAlerts:any;

  IssueDetails: IssueOption[] = [
    { value: 'audio', name: 'Audio' },
    { value: 'outlook', name: 'Outlook' },
    { value: 'storage', name: 'Storage' },
  ];
  totallength: any;
  ServiceRequesttotallength: any;
  RequestItemtotallength: any;
  Taskstotallength: any;

  page: number = 1;
  isTicketLoading: boolean = true;
  SRpage: number = 1;
  isSRLoading: boolean = true;
  RequestItempage: number = 1;
  isRequestItemLoading: boolean = true;
  TaskItempage: number = 1;
  isTaskLoading: boolean = true;

  constructor(
    public dialog: MatDialog,
    private _userpersona: UserpersonaService,
    private _supportPersonaService: SupportpersonaService,
    private modalService: NgbModal
  ) {}
  // pageEvent!: PageEvent;

  ngOnInit(): void {
     if(this._userpersona.incidentAlerts.length>0){
       this.IncidentAlerts=this._userpersona.incidentAlerts;
      //  console.log("SupportComponent ~ ngOnInit ~  this.IncidentAlerts",  this.IncidentAlerts)
    }

    this.DeviceDatas = [{ name: 'AssestName', model_id: 'modelid' }];
    this.sys_id = this._userpersona.globalUserid;
    if(this._supportPersonaService.ticketDatas==null){
      // First time Data fetching 
    if (this.sys_id) {
      
      // Get User Incident details from serviceNow
      this._supportPersonaService.getUserIncidentDetails(this.sys_id).subscribe(
        (response: any) => {
          // console.log(response);
          this.ticketDatas = response.data;
          this._supportPersonaService.ticketDatas = response.data;
          this.totallength = this.ticketDatas.length;
          this.isTicketLoading = false;
          // console.log(this.ticketDatas.length);
        },
        (error) => {
          this.isTicketLoading = false;
          console.log('error', error);
        }
      );

      // Get User Service Request details from serviceNow
      this._supportPersonaService
        .getUserServiceRequestDetails(this.sys_id)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.serviceRequestDatas = response.data;
             this._supportPersonaService.serviceRequestDatas = response.data;
            this.ServiceRequesttotallength = this.serviceRequestDatas.length;
            this.isSRLoading = false;
            // console.log(this.serviceRequestDatas.length);
          },
          (error) => {
            this.isSRLoading = false;
            console.log('error', error);
          }
        );

        // Get User Request Items from serviceNow
      this._supportPersonaService
        .getUserRequestItemDetails(this.sys_id)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.RequestItemDatas = response.data;
            this._supportPersonaService.RequestItemDatas = response.data
            this.RequestItemtotallength = this.RequestItemDatas.length;
            this.isRequestItemLoading = false;
            // console.log(this.RequestItemDatas.length);
          },
          (error) => {
            this.isRequestItemLoading = false;
            console.log('error', error);
          }
        );

         // Get User Tasks from serviceNow
      this._supportPersonaService.getUserTasks(this.sys_id).subscribe(
        (response: any) => {
          // console.log(response);
          this.TaskDatas = response.data;
          this._supportPersonaService.TaskDatas =  response.data;
          this.Taskstotallength = this.TaskDatas.length;
          this.isTaskLoading = false;
          // console.log(this.TaskDatas.length);
        },
        (error) => {
          this.isTaskLoading = false;
          console.log('error', error);
        }
      );
    }
    else{
      // Resetting datas
      this.isTicketLoading=false;
      this.isSRLoading=false;
      this.isRequestItemLoading=false;
      this.isTaskLoading=false;
      this.ticketDatas=[];
      this.serviceRequestDatas=[];
      this.RequestItemDatas=[];
      this.TaskDatas=[];

    }
  }
   else{
     // Already data loaded
     this.ticketDatas = this._supportPersonaService.ticketDatas;
     this.totallength = this._supportPersonaService.ticketDatas.length
     this.isTicketLoading = false;
     this.serviceRequestDatas = this._supportPersonaService.serviceRequestDatas;
     this.ServiceRequesttotallength = this._supportPersonaService.serviceRequestDatas.length
     this.isSRLoading=false;
     this.RequestItemDatas = this._supportPersonaService.RequestItemDatas;
     this.RequestItemtotallength = this._supportPersonaService.RequestItemDatas.length
     this.isRequestItemLoading=false;
     this.TaskDatas = this._supportPersonaService.TaskDatas;
     this.Taskstotallength = this._supportPersonaService.TaskDatas.length
     this.isTaskLoading=false;
    } 
  }


  // Search Ticket by incident number
  searchTicket(SearchedticketNumber: any) {
    this.searchTicketText = SearchedticketNumber;
    this.isTicketLoading = true;
    this._supportPersonaService
      .searchIncidentTicket(SearchedticketNumber)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.ticketDatas = response.data;
          this.isTicketLoading = false;
          // this.userInfo = response.data[0];
          // this._supportPersonaService.globalUserid = response.data[0]?.sys_id;
        },
        (error) => {
          this.isTicketLoading = false;

          console.log('error');
        }
      );
    // console.log(SearchedticketNumber);
  }

  // Search Service Request by ServiceRequest Number
  searchServiceRequest(serviceRequestNumber: any) {
    this.serviceRequestNumber = serviceRequestNumber;
    this.isSRLoading = true;
    this._supportPersonaService
      .searchServiceRequest(serviceRequestNumber)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.serviceRequestDatas = response.data;
          this.isSRLoading = false;
        },
        (error) => {
          this.isSRLoading = false;
          console.log('error');
        }
      );
    // console.log(serviceRequestNumber);
  }

  // Search Request Item by requestItem number 
  searchRequestItem(requestItemNumber: any) {
    this.requestItemNumber = requestItemNumber;
    this.isRequestItemLoading = true;
    this._supportPersonaService.searchRequestItem(requestItemNumber).subscribe(
      (response: any) => {
        // console.log(response);
        this.RequestItemDatas = response.data;
        this.isRequestItemLoading = false;
      },
      (error) => {
        this.isRequestItemLoading = false;
        console.log('error');
      }
    );
    // console.log(requestItemNumber);
  }

  // Search user Task by Task number
  searchUserTask(taskNumber: any) {
    this.taskNumber = taskNumber;
    this.isTaskLoading = true;
    this._supportPersonaService.searchUserTasks(taskNumber).subscribe(
      (response: any) => {
        // console.log(response);
        this.TaskDatas = response.data;
        this.isTaskLoading = false;
      },
      (error) => {
        this.isTaskLoading = false;
        console.log('error');
      }
    );
    // console.log(taskNumber);
  }

  displayStyle = 'none';
  displayAlertStyle = 'none';
  WorkNotesdisplayStyle = 'none';
  FixDevicedisplayStyle = 'none';
  ServiceRequestDisplayStyle = 'none';
  RequestItemDisplayStyle = 'none';
  TaskDisplayStyle = 'none';


  // ticket content popup  state-open
  openPopup(ticketContent: any,incident:any) {
    this.modalContent = ticketContent;
    this.modalService.open(incident, { size: 'xl',centered: true, });
  }

  // device content popup state - open 
  openFixPrompt(deviceDetails: any) {
    // console.log(deviceDetails);
    this.FixDeviceModalContent = deviceDetails;
    // this.modalContent = ticketContent;
    this.FixDevicedisplayStyle = 'block';
  }

  // device content popup state - close 
  closeFixPrompt() {
    this.FixDevicedisplayStyle = 'none';
  }

  // Service request Content popup state -open 
  OpenServiceRequestPopup(ServiceRequestDetails: any,serviceRequestModal:any) {
    this.ServiceRequestModalContent = ServiceRequestDetails;
     this.modalService.open(serviceRequestModal, { size: 'xl',centered: true });
    // this.ServiceRequestDisplayStyle = 'block';
  }

  // Service request Content popup state -close 
  closeServiceRequestPopup() {
    this.ServiceRequestDisplayStyle = 'none';
  }

  // Request Item Content popup state -open 
  OpenRequestItemPopup(RequestItemDetails: any,requestItemModal:any) {
    this.RequestItemModalContent = RequestItemDetails;
     this.modalService.open(requestItemModal, { size: 'xl',centered: true });
  }

  // Tasks popup state - open
  OpenTaskPopup(task: any,taskModal:any) {
    this.TaskModalContent = task;
     this.modalService.open(taskModal, { size: 'xl',centered: true });
  }

  // alert popup content state-open
  openAlertPopup(AlertContent: any) {
    this.AlertmodalContent = AlertContent;
    this.displayAlertStyle = 'block';
  }


  // Reset search incident text
  ResetSearchText() {
    // console.log('resetSerach');
    this.ticketDatas = this._supportPersonaService.ticketDatas;
  }
  // Reset Service Request search 
  ResetServiceRequestSearchText() {
    // console.log('reset searchrequest number');
    // console.log(this.allServiceRequestData);    
    this.serviceRequestDatas = this.allServiceRequestData;
  }
  // Reset Service Request Item search 
  ResetSearchServiceRequestItemText() {
    // console.log('resetSerach  RequestItem number');
    // console.log(this.allRequestItemData);    
    this.RequestItemDatas = this.allRequestItemData;
  }

  // Reset user Task search
  ResetSearchUserTaskText() {
    // console.log('resetSerach task number');
    // console.log(this.allTaskDatas);
    
    this.TaskDatas = this.allTaskDatas;
  }

  formatTicketSearchResult(t: any) {
    let result = new Array();
    t.forEach((e: any) => {
      result.push(e.item);
    });
    return result;
  }

  fuseSearch(user: any) {
    // console.log(user.target.value);

    const options = {
      includeScore: true,
      shouldSort: true,
      threshold: 0.2,
      keys: ['name', 'number'],
    };

    // if (user.target.value != "") {
    const fuse = new Fuse(this.ticketDatas, options);
    const result = fuse.search(user.target.value);
    this.ticketDatas = this.formatTicketSearchResult(result);
    // console.log(result)
    // }

    // else {
    //   this.ticketDatas = this.ticketDatas
    // }
  }
  // Redirect links 
  redirectToLink(t: string, data: string) {
    if (t === 'incident') {
      window.open(
        `https://kcc.service-now.com/nav_to.do?uri=incident.do?sys_id=${data}`,
        '_blank'
      );
    } else if (t === 'serviceRequest') {
      window.open(
        `https://kcc.service-now.com/nav_to.do?uri=u_service_request.do?sys_id=${data}`,
        '_blank'
      );
    }
     else if (t === 'requestItem') {
      window.open(
        `https://kcc.service-now.com/nav_to.do?uri=sc_req_item.do?sys_id=${data}`,
        '_blank'
      );
    }

    else if (t === 'task') {
      window.open(
        `https://kcc.service-now.com/nav_to.do?uri=sc_task.do?sys_id=${data}`,
        '_blank'
      );
    }
  }
}
