import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth-service.service';
import { ApiGatewayUrl } from 'src/app/service/baseUrl';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private authService: AuthService,

    private _router: Router
  ) {}

  ngOnInit(): void {
    // ?phoneani=1234&agentId=1234587
    this._route.queryParams.subscribe((params) => {
      const authToken = params['authToken'];
      const phoneani = params['phoneani'];
      const agentId = params['agentId'];
      // sessionStorage.setItem('authToken',authToken)
      if (!authToken) {
        console.log('redddddddddddddddd');
         sessionStorage.setItem('phoneaniLocal', params?.phoneani);  
        window.location.assign(
          'https://app-userinsights-kimclark-com-p-ncus-2.azurewebsites.net/oauth2/authorization/okta' +
            '?phoneani=' +
            phoneani +
            '&agentId=' +
            agentId
        );
      } else {
        var phoneAni = sessionStorage.getItem('phoneaniLocal');
        console.log("phoneAni",phoneAni); 
        var a = sessionStorage.getItem('authToken');
        console.log(a, 'sessionauth');
        if (a != undefined) {
          this.router.navigate(['/', 'home']);
        } else {
          window.location.assign(ApiGatewayUrl);
        }
      }
      this.fetchLoginInfo(authToken, phoneani);
      // alert(authToken)
    });
  }

  private fetchLoginInfo(authToken: string, phoneani: any) {
    sessionStorage.setItem('authToken', authToken);
    // sessionStorage.setItem('phoneani',phoneani)
    // alert("received token from okta"+authToken)
    this.authService.fetchAuthInfo(authToken).subscribe(
      (data) => {
        // alert("here"+JSON.stringify(data))
        console.log('[fetchAuthInfo]: Success!!');
        // alert(JSON.stringify(data));
        console.log(data);
        // this.userName = data["responseDetails"][0].userId;
      },
      (err) => {
        console.log('[fetchAuthInfo - Error]: ', err);
        // this.spinnerService.hide();
        // swal({
        //   text: 'Something went wrong! Please try again.',
        //   icon: 'error',
        //   className: 'swal-wide'
        // });
      }
    );
  }
}
