import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth-service.service';
import { ApiGatewayUrl } from '../../service/baseUrl';
// import swal from 'sweetalert';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
   const authToken = sessionStorage.getItem('authToken');
  const accessToken = sessionStorage.getItem('accessToken');
    // if(){

    // }
    this._route.queryParams.subscribe((params) => {

      console.log("inn home component");
      
      // const authToken = params['authToken'];
      const authToken = sessionStorage.getItem('authToken');
      const phoneani = sessionStorage.getItem('phoneani');

      if (!authToken) {
        // window.location.assign("http://localhost:4200/?authToken=1231231313")
        window.location.assign(ApiGatewayUrl);
      } else {
        // this.fetchLoginInfo(authToken,phoneani);
      }
    });
  }

  fetchLoginInfo(authToken: string, phoneani: any) {
    this._router.navigate([], {
      queryParams: {
        phoneani: phoneani,
      },
      queryParamsHandling: 'merge',
    });
    this.authService.fetchAuthInfo(authToken).subscribe(
      (data) => {
        console.log('[fetchAuthInfo]: Success!!');

        console.log(data);
        // this.userName = data["responseDetails"][0].userId;
      },
      (err) => {
        console.log('[fetchAuthInfo - Error]: ', err);
        window.location.assign(ApiGatewayUrl);
        // this.spinnerService.hide();
        // swal({
        //   text: 'Something went wrong! Please try again.',
        //   icon: 'error',
        //   className: 'swal-wide'
        // });
      }
    );
  }
}
