<div class="container p-4 mt-2 usertab">
  <div class="row">
    <div class="col-1">
      <span style="color: white" *ngIf="UserProfilePic === null">
        <i class="fas fa-user-circle fa-4x"></i>
      </span>
      <span style="color: white" *ngIf="UserProfilePic !== null">
        <img
          src="{{ UserProfilePic }}"
          alt="Avatar"
          style="height: 75px; width: 75px"
        />
      </span>
    </div>

    <div class="col-8 mt-1">
      <p
        class="textfontcolor"
        style="
          text-transform: uppercase;
          font-weight: 600;
          font-size: 20px;
          color: white;
        "
      >
        {{ userInfo?.name ? userInfo?.name : notAvailableMsg }}
      </p>
      <!-- <p class="box_text">{{userInfo.user_name ? userInfo.user_name: "null"}}</p> -->
      <span class="textfontcolor">{{
        userInfo?.title ? userInfo?.title : notAvailableMsg
      }}</span>
    
      <!-- <div>
      <li *ngFor="let a of [].constructor(4)">
          <i class="fa fa-star" style="color: #ffd700;"></i>
      </li> 
    </div> -->
<!-- <div>
  <ngb-rating [rate]="finalStartRating"  style="color: #ffd700;font-size: 28px; margin-top: 7px;"></ngb-rating>

</div> -->  




<div class="crop" [style.width.px]="finalStartRating" style=" margin-top: 7px;" *ngIf="isRating;else elseBlock ">
  <div style="width: 90px">
    <span class="fa fa-star" style="color: #ffd700;font-size: 20px"></span>
    <span class="fa fa-star" style="color: #ffd700;font-size: 20px"></span>
    <span class="fa fa-star" style="color: #ffd700;font-size: 20px"></span>
    <span class="fa fa-star" style="color: #ffd700;font-size: 20px"></span>
  </div>
</div>
<ng-template #elseBlock>

  <p style="color: white;margin-top: 7px;">No Rating</p>
</ng-template>

    </div>

    <div class="col" style="display: flex">
      <!-- <input type="text"    (keydown.enter)="searchuserbyUserid($event)"/>
     <mat-icon
            matSuffix
            style="color: white;"
            >search</mat-icon
          > -->
      <div class="input-group" style="width: 100%">
        <input
          placeholder="Search User"
          class="form-control border-end-0 border rounded-pill"
          type="text"
          (keydown.enter)="searchuserbyUserid($event)"
        />
      </div>
      <!-- <mat-form-field class="example-full-width"> -->
      <!-- <mat-label class="text-white">ID Lookup </mat-label>
          <input type="text"  #searchUserid />
          <mat-icon
            matSuffix
            style="color: white; cursor: pointer"
            (click)="searchuserbyUserid(searchUserid.value)"
            >search</mat-icon
          > -->
      <!-- </mat-form-field> -->

      <div style="padding-left: 6%; width: auto" *ngIf="BadgeLength > 0">
        <button mat-icon-button [matMenuTriggerFor]="noitificationMenu">
          <mat-icon
            class="badge_notification"
            [matBadge]="BadgeLength"
            matBadgeColor="warn"
            >notifications</mat-icon
          >
        </button>
        <mat-menu #noitificationMenu>
          <div *ngFor="let Alertdetails of IncidentAlertDatas">
            <button
              mat-menu-item
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
              (click)="openAlertPopup(Alertdetails, AlertModal)"
            >
              <span class="NotificationText">{{
                Alertdetails?.u_email_subject
              }}</span>
              <mat-icon style="padding-left: 11%">message</mat-icon>
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<!-- <mat-tab-group mat-stretch-tabs class="streached"> -->
<!-- <mat-tab-group mat-align-tabs="center" class="streached"> -->

<div class="container cont-align mt-1">
  <mat-tab-group mat-align-tabs="center" class="streached">
    <mat-tab label="User">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">man</i>

          <span class="title iconwithtitle">User</span>
        </ng-template>
      </div>

      <ng-template matTabContent>
        <div class="contentdiv">
          <div class="row">
            <div class="col-8" style="border-right: 1px solid #10069f">
              <div class="row mt-4 m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons iconstyle">verified_user</i>
                    <p class="titleForm">USER ID</p>
                  </div>
                  <!-- <p class="box_text">name</p> -->

                  <p class="box_text">
                    {{
                      userInfo?.user_name
                        ? userInfo?.user_name
                        : notAvailableMsg
                    }}
                  </p>
                  <!-- <span class="badge badge-secondary badge-conf">{{userInfo.user_name ? userInfo.user_name: "null"}}</span> -->
                  <!-- <p class="box_text">{{userInfo.user_name ? userInfo.user_name: "null"}}</p> -->
                </div>
                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">mark_email_read</i>
                    <p class="titleForm">EMAIL</p>
                  </div>

                  <!-- <p class="box_text">{{userInfo.email}}</p> -->
                  <!-- <p class="box_text">{{userInfo.email ? userInfo.email: notAvailableMsg}}</p> -->
                  <p class="box_text" style="word-wrap: break-word">
                    {{ userInfo?.email ? userInfo?.email : notAvailableMsg }}
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons">manage_accounts</i>
                    <p class="titleForm">MANAGER</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.manager ? userInfo?.manager : notAvailableMsg
                    }}
                  </p>
                </div>

                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">account_balance</i>
                    <p class="titleForm">DEPARTMENT</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.department
                        ? userInfo?.department
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons iconstyle">security</i>
                    <p class="titleForm">WORKER TYPE</p>
                  </div>
                  <p class="box_text">
                    {{
                      OktaUserInfo?.profile["jobcategory"]
                        ? OktaUserInfo?.profile["jobcategory"]
                        : notAvailableMsg
                    }}
                  </p>
                </div>

                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons">timer</i>
                    <p class="titleForm">TIME ZONE</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.time_zone
                        ? userInfo?.time_zone
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons">mobile_friendly</i>
                    <p class="titleForm">MOBILE PHONE</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.mobile_phone
                        ? userInfo?.mobile_phone
                        : notAvailableMsg
                    }}
                  </p>
                </div>
                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">security</i>
                    <p class="titleForm">L4 ORGANIZATION</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.u_l4_organization
                        ? userInfo?.u_l4_organization
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons iconstyle">phone</i>
                    <p class="titleForm">BUSINESS PHONE</p>
                  </div>
                  <p class="box_text">
                    {{ userInfo?.phone ? userInfo?.phone : notAvailableMsg }}
                  </p>
                </div>

                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">settings_phone</i>
                    <p class="titleForm">PERSONAL PHONE</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.u_personal_phone
                        ? userInfo?.u_personal_phone
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons iconstyle">location_pin</i>
                    <p class="titleForm">COUNTRY</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.country ? userInfo?.country : notAvailableMsg
                    }}
                  </p>
                </div>
                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">location_city</i>
                    <p class="titleForm">LOCATION</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.location ? userInfo?.location : notAvailableMsg
                    }}

                    &nbsp;&nbsp;-&nbsp;&nbsp;<span>
                      {{
                        userInfo?.u_ou ? userInfo?.u_ou : notAvailableMsg
                      }}</span
                    >
                  </p>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-4">
                  <div class="useritems">
                    <i class="material-icons iconstyle">today</i>
                    <p class="titleForm">CREATED</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.sys_created_on
                        ? userInfo?.sys_created_on
                        : notAvailableMsg
                    }}
                  </p>
                </div>
                <div class="col-8">
                  <div class="useritems">
                    <i class="material-icons iconstyle">access_time_filled</i>
                    <p class="titleForm">LAST LOGIN</p>
                  </div>
                  <p class="box_text">
                    {{
                      userInfo?.u_last_imported
                        ? userInfo?.u_last_imported
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!--Right column in user-->

            <div class="col-4">
              <div class="row mt-4 m-2">
                <div class="col-8">
                  <p class="titleFormright">ACCOUNT ENABLED (AD)</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                        *ngIf="!(aadUserinfo?.enabled == true); else elseBlock"
                      >
                        <input
                          class="switch-input cursor-contextMenu"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">LOCKOUT (AD)</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                      *ngIf="!(aadUserinfo?.lockedOut == true); else elseBlock"
                    >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">INTERNET ACCESS</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                      *ngIf="!(aadUserinfo?.hasInternetAccess == true); else elseBlock"
                    >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
             
              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">EXTERNAL MAIL ACCESS</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                      *ngIf="!(aadUserinfo?.hasExternalEmail == true); else elseBlock"
                    >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">MAILBOX</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                      *ngIf="!(aadUserinfo?.hasMailbox == true); else elseBlock"
                    >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">AAD SYNC</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                      *ngIf="!(aadUserinfo?.syncedToAAD == true); else elseBlock"
                    >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">GSLT</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                        *ngIf="!(userInfo?.u_gslt === 'true'); else elseBlock"
                      >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-8">
                  <p class="titleFormright">SITE SUPPORT</p>
                </div>
                <div class="col-4">
                  <div class="center">
                    <label class="switch switch-left-right">
                      <ng-template
                        *ngIf="
                          !(userInfo?.u_site_support === 'true');
                          else elseBlock
                        "
                      >
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          disabled
                        />
                      </ng-template>
                      <ng-template #elseBlock>
                        <input
                          class="switch-input"
                          id="myCheck"
                          type="checkbox"
                          checked
                          disabled
                        />
                      </ng-template>
                      <span
                        class="switch-label"
                        data-on="YES"
                        data-off="NO"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-8" style="display: flex">
                  <p class="titleFormright">VIP STATUS</p>
                  <!-- <div *ngif="true">  -->

                  <i
                    class="fas fa-crown fa-lg"
                    [ngStyle]="{
                      color: true ? 'red' : 'grey',
                      'font-weight': 'bold'
                    }"
                  ></i>

                  <!-- </div> -->
                </div>
                <div class="col-4">
                  <div class="center">
                    <div class="center">
                      <label class="switch switch-left-right">
                        <ng-template
                          *ngIf="!(userInfo?.vip === 'true'); else elseBlock"
                        >
                          <input
                            class="switch-input"
                            id="myCheck"
                            type="checkbox"
                          />
                        </ng-template>
                        <ng-template #elseBlock>
                          <input
                            class="switch-input"
                            id="myCheck"
                            type="checkbox"
                            checked
                          />
                        </ng-template>
                        <span
                          class="switch-label"
                          data-on="YES"
                          data-off="NO"
                        ></span>
                        <span class="switch-handle"></span>
                      </label>
                    </div>
                    <!-- <div style="display:flex">
                      <i class="fas fa-crown fa-lg" [ngStyle]="{'color':true?'red':'grey', 'font-weight':'bold'}"></i>
                      <p style="font-size: 17px;margin-left: 3px;">{{true?'YES':'NO'}}</p>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-8" style="display: flex">
                  <p class="titleFormright">ZOOM PHONE</p>
                  <!-- <div *ngif="true">  -->

                  
                  <!-- </div> -->
                </div>
                <div class="col-4">
                  <div class="center">
                    <div class="center">
                      <label class="switch switch-left-right">
                        <ng-template
                      *ngIf="!(zoomphone == true); else elseBlock"
                    >

                          <input
                            class="switch-input"
                            id="myCheck"
                            type="checkbox"
                          />
                        </ng-template>
                        <ng-template #elseBlock>
                          <input
                            class="switch-input"
                            id="myCheck"
                            type="checkbox"
                            checked
                          />
                        </ng-template>
                        <span
                          class="switch-label"
                          data-on="YES"
                          data-off="NO"
                        ></span>
                        <span class="switch-handle"></span>
                      </label>
                    </div>
                    <!-- <div style="display:flex">
                      <i class="fas fa-crown fa-lg" [ngStyle]="{'color':true?'red':'grey', 'font-weight':'bold'}"></i>
                      <p style="font-size: 17px;margin-left: 3px;">{{true?'YES':'NO'}}</p>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-8" style="display: flex;flex-direction: column">
                  <p class="titleForm">HOME DIRECTORY</p>
                  <!-- <p style="word-break: break-all;">{{aadUserinfo.homeDirectory}}</p> -->
                  <p  class="titleForm" style="color: #0F059E !important">{{aadUserinfo?.homeDirectory?aadUserinfo?.homeDirectory:notAvailableMsg}}</p>

                  <!-- <div *ngif="true">  -->

                  

                  <!-- </div> -->
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- <mat-tab label="Preferences">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">settings</i>
          <span class="title iconwithtitle">Preferences</span>
        </ng-template>
      </div>

      <div class="contentdiv">
        <div class="row m-4 border-bottom">
          <div class="col-6">
            <p class="titleForm">Time</p>
          </div>
          <div class="col-6">
            <p class="box_text">
              {{
                userInfo?.time_format ? userInfo?.time_format : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="row m-4 border-bottom">
          <div class="col-sm-6">
            <p class="titleForm">Language</p>
          </div>
          <div class="col-sm-6">
            <p class="box_text">
              {{
                userInfo?.preferred_language
                  ? userInfo?.preferred_language
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="row m-4 border-bottom">
          <div class="col-sm-6">
            <p class="titleForm">Service Type</p>
          </div>
          <div class="col-sm-6">
            <p class="box_text">sample2</p>
          </div>
        </div>
      </div>
    </mat-tab> -->

    <mat-tab label="Okta Info">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">work</i>
          <span class="title iconwithtitle">Okta Info</span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <!-- style="border-right: 1px solid #10069f" -->
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Status</p>
                  </div>
                  <div class="col-sm-6">
                    <p
                      class="box_text"
                      style="align-items: flex-end; display: flex"
                    >
                      <i
                        class="material-icons"
                        style="margin-right: 5px; color: green"
                        *ngIf="OktaUserInfo?.status === 'ACTIVE'"
                        >check_circle</i
                      >
                      <i
                        class="material-icons"
                        style="margin-right: 5px; color: #556b2f"
                        *ngIf="OktaUserInfo?.status === 'STAGED'"
                        >fiber_new</i
                      >
                      <i
                        class="material-icons"
                        style="margin-right: 5px; color: orange"
                        *ngIf="OktaUserInfo?.status === 'RECOVERY'"
                        >settings_applications</i
                      >
                      <i
                        class="material-icons"
                        style="margin-right: 5px; color: #7e8185"
                        *ngIf="OktaUserInfo?.status === 'PROVISIONED'"
                        >settings_applications</i
                      >
                      {{
                        OktaUserInfo?.status
                          ? OktaUserInfo?.status
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Activated</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.activated
                          ? (OktaUserInfo?.activated | date: "medium")
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Language</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="box_text">
                      {{
                        userInfo?.preferred_language
                          ? userInfo?.preferred_language
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Last Login</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.lastLogin
                          ? (OktaUserInfo?.lastLogin | date: "medium")
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Password Last Set</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.profile?.pwdLastSet
                          ? (OktaUserInfo?.profile?.pwdLastSet )
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Manager ID</p>
                  </div>
                  <div class="col-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.profile["manager"]
                          ? OktaUserInfo?.profile["manager"]
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">MFA</p>
                  </div>
                  <div class="col-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.profile["MFA"]
                          ? (OktaUserInfo?.profile["MFA"] | uppercase)
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Status Changed</p>
                  </div>
                  <div class="col-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.statusChanged
                          ? (OktaUserInfo?.statusChanged | date: "medium")
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Last Updated</p>
                  </div>
                  <div class="col-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.lastUpdated
                          ? (OktaUserInfo?.lastUpdated | date: "medium")
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-4 border-bottom">
                  <div class="col-sm-6">
                    <p class="titleForm">Password Changed</p>
                  </div>
                  <div class="col-6">
                    <p class="box_text">
                      {{
                        OktaUserInfo?.passwordChanged
                          ? (OktaUserInfo?.passwordChanged | date: "medium")
                          : notAvailableMsg
                      }}
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-1 pb-2">
            <div style="background: grey">
              <p
                style="
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  padding: 7px;
                "
              >
                <mat-icon style="margin-right: 5px">event</mat-icon> Okta User
                Logs (Last 5 Transactions)
              </p>
            </div>
            <div *ngIf="!isOktaLogsLoading">
              <div *ngFor="let logs of UserLogs">
                <div class="m-2">
                  <mat-accordion>
                    <mat-expansion-panel class="example-headers-align m-1">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ logs?.published | date: "medium" }}
                        </mat-panel-title>
                        <mat-panel-description>
                          <div>
                            <span> {{ logs?.outcome?.result }}</span>
                            &nbsp;<span>{{ logs?.outcome?.reason }}</span>
                          </div>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <div class="col-6">
                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">Display Message</p>
                            </div>

                            <div class="col-sm-6">
                              <p
                                class="box_text"
                                style="align-items: flex-end; display: flex"
                              >
                                {{
                                  logs?.displayMessage
                                    ? logs?.displayMessage
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">Device</p>
                            </div>

                            <div class="col-sm-6">
                              <p
                                class="box_text"
                                style="align-items: flex-end; display: flex"
                              >
                                {{
                                  logs?.client?.device
                                    ? logs?.client?.device
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">Browser</p>
                            </div>
                            <div class="col-sm-6">
                              <p class="box_text">
                                {{
                                  logs?.client?.userAgent?.browser
                                    ? logs?.client?.userAgent?.browser
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">OS</p>
                            </div>
                            <div class="col-6">
                              <p class="box_text">
                                {{
                                  logs?.client?.userAgent?.os
                                    ? logs?.client?.userAgent?.os
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>

                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">Event Type</p>
                            </div>
                            <div class="col-6">
                              <p class="box_text">
                                {{
                                  logs?.eventType
                                    ? logs?.eventType
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>

                          <div class="row m-4 border-bottom">
                            <div class="col-sm-6">
                              <p class="titleForm">Severity</p>
                            </div>
                            <div class="col-6">
                              <p class="box_text">
                                {{
                                  logs?.severity
                                    ? logs?.severity
                                    : notAvailableMsg
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
            <div
              style="padding-top: 4%"
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="isOktaLogsLoading"
            >
              <div>
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div style="padding-top: 3%">
                <strong>Please Wait ... </strong>
              </div>
            </div>
            <div
              style="padding-top: 1%; padding-bottom: 3%"
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="UserLogs?.length === 0 ||UserLogs===null  && !isOktaLogsLoading"
            >
              <div style="padding-top: 2%">
                <p>No logs Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Licensing">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">collections_bookmark</i>
          <span class="title iconwithtitle">Licensing</span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <div class="m-4">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            "
          >
            <div
              style="display: flex; flex-direction: row; align-items: center"
            >
              <i class="material-icons iconstyle" style="color: green"
                >check_circle</i
              >Enabled Services
            </div>

            <div
              style="display: flex; flex-direction: row; align-items: center"
            >
              <i class="material-icons iconstyle" style="color: red">cancel</i
              >Disabled Services
            </div>
          </div>

          <div *ngFor="let details of LicenseDetailsAll">
            <hr />
            <div>
              <div>
                <div>
                  <div style="text-align: center">
                    <span *ngIf="details.name == 'SPE_E3'">
                      <button
                        type="button"
                        style="
                          height: 30px;
                          padding-top: 2px;
                          cursor: context-menu;
                        "
                        class="btn btn-secondary mx-2"
                      >
                        E3 License
                      </button>
                    </span>
                    <span
                      *ngIf="details.name == 'ENTERPRISEPACKWITHOUTPROPLUS'"
                    >
                      <button
                        type="button"
                        style="
                          height: 30px;
                          padding-top: 2px;
                          cursor: context-menu;
                        "
                        class="btn btn-danger mx-2"
                      >
                        E3M License
                      </button>
                    </span>
                  </div>
                  <p style="text-align: center">{{ details.name }}</p>
                </div>
              </div>
            </div>
            <hr />

            <div class="row m-3">
              <div class="col-8">
                <div *ngFor="let enable of details.enabled">
                  <p
                    style="
                      margin-left: 3px;
                      font-size: 14px;
                      margin-right: 3px;
                      align-items: flex-end;
                      white-space: nowrap;
                      color: black;
                      display: flex;
                    "
                  >
                    <i
                      class="material-icons"
                      style="margin-right: 5px; color: #7e8185"
                      >settings_applications</i
                    >
                    {{ enable }}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div *ngFor="let disable of details.disabled">
                  <p
                    style="
                      margin-left: 3px;
                      font-size: 14px;
                      margin-right: 3px;
                      align-items: flex-end;
                      white-space: nowrap;
                      color: black;
                      display: flex;
                    "
                  >
                    <i
                      class="material-icons"
                      style="margin-right: 5px; color: #7e8185"
                      >settings_applications</i
                    >
                    {{ disable }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="padding-top: 1%; padding-bottom: 3%"
          class="text-center flex flex-column justify-content-center align-items-center"
          *ngIf="LicenseDetailsAll?.length == 0"
        >
          <div style="padding-top: 2%">
            <p>No Details Found</p>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label=" Group Membership">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">group</i>
          <span class="title iconwithtitle">Group Membership</span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <div>
          <div
            class="form-group"
            style="
              display: flex;
              align-content: stretch;
              justify-content: center;
            "
          >
            <input
              type="email"
              class="form-control"
              id="search-text"
              aria-describedby="search-text"
              [(ngModel)]="searchText"
              placeholder="Enter text to search"
              autofocus
              style="margin-top: 10px; width: 600px"

            
            />
          </div>
          <div class="row m-4 border-bottom" *ngIf="!loadingUserGrp">
            <div
              class="col-6"
              style="word-break: break-all"
              *ngFor="let value of sortedGroup | appFilter: searchText"
            >
              <p
                style="
                  margin-left: 3px;
                  font-size: 14px;
                  margin-right: 3px;
                  align-items: flex-end;
                  white-space: nowrap;
                  color: black;
                  display: flex;
                "
                *ngIf="value != -1"
              >
                <i
                  class="material-icons"
                  style="margin-right: 5px; color: #7e8185"
                  >folder_shared</i
                >
                {{ value }}
              </p>
              <p
                style="
                  margin-left: 3px;
                  font-size: 14px;
                  margin-right: 3px;
                  align-items: flex-end;
                  white-space: nowrap;
                  color: black;
                  display: flex;
                "                
                *ngIf="sortedGroup?.length > 0 && value == -1"
              >              
                  No Result Found           
              </p>
            </div>
          </div>
        </div>

        <div
          style="padding-top: 1%; padding-bottom: 3%"
          class="text-center flex flex-column justify-content-center align-items-center"
          *ngIf="sortedGroup?.length == 0 && !loadingUserGrp"
        >
          <div style="padding-top: 2%">
            <p>No Details Found</p>
          </div>
        </div>

        <div
          class="text-center flex flex-column justify-content-center align-items-center"
          *ngIf="loadingUserGrp"
          style="padding-top: 2%;padding-bottom: 2%;"
        >
          <div>
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <div style="padding-top: 3%">
            <strong>Please Wait ... </strong>
          </div>
        </div>

      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- </mat-card> -->

  <!-- </mat-card> -->
</div>

<!-- <div id="cover-spin" *ngIf="isLoading"> 
  <div id="spinner">
  </div>
</div> -->

<!-- <div class="text-center flex flex-column justify-content-center align-items-center spinner"
              *ngIf="isLoading"
            >
              <div>
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div style="padding-top: 3%">
                <strong>Please Wait ... </strong>
              </div>
      </div> -->

<!-- Incident Alert Modal -->
<ng-template #AlertModal let-modal>
  <div>
    <div
      class="modal-full-height modal-xl modal-right modal-dialog-centered modal-notify modal-info"
    >
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <p class="heading lead">
            <i class="far fa-file-alt" style="padding-right: 10px"> </i>
            {{ AlertmodalContent?.number }}
          </p>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.close('Close click')"
          >
            <span aria-hidden="true" class="white-text">×</span>
          </button>
        </div>

        <!--Body-->
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Number:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.number
                        ? AlertmodalContent?.number
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label">Incident Number:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.source_incident
                        ? AlertmodalContent?.source_incident
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Alert severity:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.severity
                        ? AlertmodalContent?.severity
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label">State:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.state
                        ? AlertmodalContent?.state
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Incident Priority:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_incident_priority
                        ? AlertmodalContent?.u_incident_priority
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label">Created at:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.sys_created_on
                        ? AlertmodalContent?.sys_created_on
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Last communication:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_communication_status
                        ? AlertmodalContent?.u_communication_status
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label">Assignment group:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.assignment_group
                        ? AlertmodalContent?.assignment_group
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Event Type:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.event_type
                        ? AlertmodalContent?.event_type
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label">Location:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_location
                        ? AlertmodalContent?.u_location
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label">Incident Manager:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_incident_manager
                        ? AlertmodalContent?.u_incident_manager
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label">L4 Leader:</label>
                <div class="col-8">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_l4_leader
                        ? AlertmodalContent?.u_l4_leader
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group row">
                <label class="col-2 col-form-label">Subject:</label>
                <div class="col-10">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_email_subject
                        ? AlertmodalContent?.u_email_subject
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label">Inital Message:</label>
                <div class="col-10">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_email_initial_message
                        ? AlertmodalContent?.u_email_initial_message
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label">Resolve Message:</label>
                <div class="col-10">
                  <p class="mb-0 form-control-plaintext">
                    {{
                      AlertmodalContent?.u_email_resolve_message
                        ? AlertmodalContent?.u_email_resolve_message
                        : notAvailableMsg
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <a
            type="button"
            class="btn btn-outline-primary waves-effect"
            data-dismiss="modal"
            (click)="modal.close('Close click')"
            >Exit</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>
