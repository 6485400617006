import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import * as _ from 'lodash';
import { SupportpersonaService } from 'src/app/service/support/supportpersona.service';
import { UserpersonaService } from 'src/app/service/user/userpersona.service';
// import  data from '../../testdata.json';
// import * as data from '../../testdata.json';

import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
   sys_id:any;
   panelOpenState:boolean = true;
   isChartsAvailable:boolean=false
   // ticket: any = (data as any).default;
   ticket:any;
   asmtResult:any;
   chart1:any={
      key:[],
      value:[]
   }

   chart2:any={
      key:[],
      value:[]
   }
   chart3:any={
      key:[],
      value:[]
   }
   chart4:any={
      key:[],
      value:[]
   }

   chart5data:any=[0,0,0,0];
   chart5:any={
      key:[],
      value:[]
   }


   favoritesUrl:any=[
   {
     "Title":"Account Manager",
     "Url":"https://ws.kcc.com/accountmanager/"
  },
  {
     "Title":"@K-C Tools",
     "Url":"https://kimberlyclark.sharepoint.com/Pages/Tools.aspx"
  },
  {
     "Title":"SharePoint Sites",
     "Url":"https://kimberlyclark.sharepoint.com/_layouts/15/sharepoint.aspx?OR=Teams-HL&CT=1629893869754"
  },
  {
     "Title":"Bitlocker Console",
     "Url":"http://gcps.kcc.com/Bitlocker/Default.aspx?searchValue="
  },
  {
     "Title":"Desk Portal",
     "Url":"https://kcc.service-now.com/kc_sp?sysparm_stack=no"
  },
  {
     "Title":"Group Manager",
     "Url":"https://ws.kcc.com/GroupManager/"
  },
  {
     "Title":"Identity Manager",
     "Url":"http://idm.kcc.com/"
  },
  {
     "Title":"IT Service Catalog",
     "Url":"https://kcc.service-now.com/kc_sp?id=kc_sc_home"
  },
  {
     "Title":"K-C Home Page",
     "Url":"http://kchome.kcc.com/"
  },
  {
     "Title":"K-C SAP Portal",
     "Url":"http://atkc.kcc.com/irj/portal"
  },
  {
    "Title":"Mobility Registration",
     "Url":"http://www.mobilityservices.kcc.com/registration"
  },
  {
    "Title":"Mobility User Info",
     "Url":"http://mobilityservices.kcc.com/registration/Support/UserInfo/"
  },
  {
    "Title":"OKTA EBIZ",
     "Url":"https://ebiz.okta.com/"
  },
  {
     "Title":"OKTA Preview",
     "Url":"https://kcc.oktapreview.com/"
  },
  
  {
     "Title":"OKTA Production",
     "Url":"https://kcc.okta.com/"
  },
  {
     "Title":"Self Service Form",
     "Url":"https://kcc.service-now.com/kc_sp?id=sc_cat_item&sys_id=98941aa20a0a3c470178a899f90006f2&table=sc_cat_item"
  },
  {
     "Title":"Service Now",
     "Url":"https://kcc.service-now.com/"
  },
  {
     "Title":"Splunk",
     "Url":"https://kcc.splunkcloud.com/en-US/app/search/search"
  },
  {
     "Title":"The DESK KB Videos",
     "Url":"https://web.microsoftstream.com/channel/afb12200-f860-4228-8d59-72b3dc0bd6f5"
  },
  {
     "Title":"Workday",
     "Url":"https://www.myworkday.com/kimberlyclark/d/home.htmld"
  }]
   
  constructor(private _supportPersonaService:SupportpersonaService,  private _userpersona: UserpersonaService,) { }

  ngOnInit(): void {
   this.ticket=this.ticket
   this.sys_id = this._userpersona.globalUserid;

   if(this.sys_id){


      forkJoin([
         this._supportPersonaService.Last90daysIncident(this.sys_id),
        
      
      ])
     
      .subscribe(
        (response: any) => {
        console.log("FavoritesComponent ~ ngOnInit ~ response", response)
         this.ticket=response[0].data
         this.asmtResult=this._userpersona.globalCustomerSatifiaction
         this.isChartsAvailable=true;
         this.chartForContactType();
         this.chartForCmdbci();
         this.chartForCategory();
         this.chartForsubCategory();
         this.chartForCustomerSatifaction();
         //  console.log(response.data );
        },
        (error) => {
         this.isChartsAvailable=false;
          console.log('error');
        }
      )
   }
   // this.chartForContactType();
   // this.chartForCmdbci();
   // this.chartForCatergory();
   // this.chartForC();
  }
  chartForCustomerSatifaction(){
   let groupby=_.groupBy(this.asmtResult, 'normalized_value');
   console.log("FavoritesComponent ~ ngOnInit ~ groupby", groupby)
    this.chart5.key=Object.keys(groupby)
    console.log("FavoritesComponent ~ chartForCustomerSatifaction ~ this.chart5.key", this.chart5.key)
   for(let a of this.chart5.key){
      console.log(a+"dsafffffffffffffff")
      if(a==0){
         let value=groupby[a].length
      this.chart5data[0]=value
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==1.67){
         let value=groupby[a].length
      this.chart5data[1]=value
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==3.33){
      let value=groupby[a].length
      this.chart5data[2]=value
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==5){
         let value=groupby[a].length
      this.chart5data[3]=value
      this.chart5.value.push(groupby[a].length)
      }
      }
      console.log("FavoritesComponent ~ chartForCustomerSatifaction ~ chart5data", this.chart5data)
      console.log(this.chart5.value+"adsfds")
   const myChart5 = new Chart("myChart5", {
      type: 'bar',
      data: {
          labels: ["Very Poor","Poor","Good","Excellent"],
          datasets: [{
              label: 'Count',
              data:this.chart5data,
              backgroundColor: [
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  
              ],
             
            
          },
         ]
      },

      options: {
         scales: {
             yAxes: [{
                 ticks: {
                     beginAtZero: true,
                    
                 }
             }]
         }
     }

   });

  }
  chartForContactType(){

   let groupby=_.groupBy(this.ticket, 'contact_type');
    this.chart1.key=Object.keys(groupby)
   for(let a of this.chart1.key){
      this.chart1.value.push(groupby[a].length)
   }
   // console.log(this.chart1.key.length+"sdafasdffsad")
   // console.log( this.chart1.value)


   const myChart = new Chart("myChart", {
      type: 'pie',
      data: {
          labels: this.chart1.key,
          datasets: [{
              label: 'Count',
              data:this.chart1.value,
              backgroundColor: [
                 "#89CFF0",
                  '#0000FF',
                  '#7393B3',
                  '#0096FF',
                  '#5F9EA0',
                  '#6495ED',
                  '#6F8FAF',
                  '#5D3FD3',

              ],
             
            
          }]
      },
   
   });
  
  }

  chartForCmdbci(){

   let groupby=_.groupBy(this.ticket, 'cmdb_ci');
    this.chart2.key=Object.keys(groupby)
   for(let a of this.chart2.key){
      this.chart2.value.push(groupby[a].length)
   }
   // console.log(this.chart2.key)
   this.chart2.key.f
   // console.log( this.chart2.value)

   const myChart1 = new Chart("myChart1", {
      type: 'bar',
      data: {
          labels: this.chart2.key,
          datasets: [{
              label: 'Count',
              data:this.chart2.value,
              backgroundColor: [
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  
              ],
             
            
          },
         ]
      },
      options: {
         scales: {
            xAxes: [{
                ticks: {
                    display: false,
                    beginAtZero: true
                }
            }]
        },
     }
   });
  
  }

  chartForCategory(){

   let groupby=_.groupBy(this.ticket, 'category');
    this.chart3.key=Object.keys(groupby)
   for(let a of this.chart3.key){
      this.chart3.value.push(groupby[a].length)
   }
   // console.log(this.chart3.key+"chart3")
   // console.log( this.chart3.value+"chart3")

   const myChart1 = new Chart("myChart2", {
      type: 'pie',
      data: {
          labels: this.chart3.key,
          datasets: [{
              label: 'Count',
              data:this.chart3.value,
              backgroundColor: [
               "#89CFF0",
               '#0000FF',
               '#7393B3',
               '#0096FF',
               '#5F9EA0',
               '#6495ED',
               '#6F8FAF',
               '#5D3FD3',
                  
              ],
             
            
          }]
      },
      options: {
         scales: {
             yAxes: [{
                 ticks: {
                     beginAtZero: true
                 }
             }]
         }
     }
   });
  
  }

  chartForsubCategory(){

   let groupby=_.groupBy(this.ticket, 'subcategory');
    this.chart4.key=Object.keys(groupby)
   for(let a of this.chart4.key){
      this.chart4.value.push(groupby[a].length)
   }
   // console.log(this.chart4.key+"chart4")
   // console.log( this.chart4.value+"chart4")

   const myChart1 = new Chart("myChart3", {
      type: 'bar',
      data: {
          labels: this.chart4.key,
          datasets: [{
              label: 'Count',
              data:this.chart4.value,
              backgroundColor: [
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  '#10069F',
                  
              ],
             
            
          }]
      },
      options: {
         scales: {
             yAxes: [{
                 ticks: {
                     beginAtZero: true,
                    
                 }
             }]
         }
     }
   });
  
  }


//   chartForCatergory(){
//    let groupby=_.groupBy(this.ticket, 'category');
//    let groupby2=_.groupBy(this.ticket, 'subcategory');

//    console.log(groupby)
//    console.log(groupby2)

//    //  this.chart2.key=Object.keys(groupby)
//    // for(let a of this.chart2.key){
//    //    this.chart2.value.push(groupby[a].length)
//    // }
//    // console.log(this.chart2.key)
//    // console.log( this.chart2.value)

//   }

  redirectToLink(link:any){
    window.open(
      link,
      '_blank'
    );
  }

 

}
//    let chartData2:any={};
//          this.ticket.forEach((e: any) => {
//          if(e?.category in chartData2){
//             // add count
//             chartData2[e?.category] ={ data : chartData2[e?.category].data + 1}
//          }
//          else{
//             //create key
//              chartData2[e?.category] =  { data :  1}
//          }
//     });
//     console.log(chartData2);
// console.log(Object.keys(chartData2));
   //   const myChart = new Chart("myChart", {
   //        type: 'bar',
   //        data: {
   //            labels: ['Email', 'Chat', 'Phone', 'Self-Service'],
   //            datasets: [{
   //                label: '',
   //                data: [ chartData["emailCount"], chartData["chatCount"], chartData["phoneCount"], chartData["selfServiceCount"]],
   //                backgroundColor: [
   //                    '#10069F',
   //                    '#10069F',
   //                    '#10069F',
   //                    '#10069F',
   //                    '#10069F',
   //                    '#10069F',
   //                ],
   //            }]
   //        },

   //    });