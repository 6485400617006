import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrl } from '../baseUrl';

@Injectable({
  providedIn: 'root',
})
export class UserpersonaService {
  globalUserid: any;
  globalUserEmail: any;
  globalUsername: any;
  globalUserProfilePic:any=null
  globalUserAAD:any=null

  oktaUserInfo: any;
  servicenowUserInfo: any;
  oktaGroupmembership: any=[];
  globalLicense: any=[];
  incidentAlerts: any;
  globalUserLogs: any=[];
  globalOktaAccessToken:any=null;
  oktaEmailMisMatch:boolean=false;
  globalCustomerSatifiaction:any=null;
  buttondetails:any=null;
  finalStartRating:any;
  
  constructor(private http: HttpClient) {}

  public getUserInfo(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'sysUserPhoneMultipleSearch',
      payload: {
        phoneani: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }

  public getUserbyUserid(userid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      action: 'UserdetailbyUserid',
      payload: {
        userId: userid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }

  public getIncidentAlert() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      action: 'Last24hrsIncidentAlert',
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }

  public getLicenseDetails(email: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      action: 'getUserlicenseDetails',
      payload: {
        email: email,
      },
    };

    return this.http.post(BaseUrl + 'esi/ad', data, options);
  }

  public getUserProfilePic(email: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      action: 'getUserProfilePhoto',
      payload: {
        email: email,
        size:"120x120"
      },
    };

    return this.http.post(BaseUrl + 'esi/ad', data, options);
  }


  public getUserAADinfo(userid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      "action": "getUserinfoAAD",
      "payload": {
          "userId": userid
      }
  };

    return this.http.post(BaseUrl + 'esi/ad', data, options);
  }
  public resetOktaLogs(){
    this.globalUserLogs=[];
    this.oktaGroupmembership=[];
    this.globalLicense=[]
    this.globalUserAAD=null
  }

  public customerSatisifaction(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'Asmtmetric',
      payload: {
        sys_id: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
}
