import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFilter'
})
export class AppFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    let datas = items.filter(it => {
      return it.toLocaleLowerCase().includes(searchText);
    })

    if(datas?.length){    
      return datas; 
    }
    else{
      return [-1]
    }
   
  }
}
