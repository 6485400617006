



  <!-- <div class="search-bar">
    <div class="p-2">
        <div class="input-group mb-3"> <input type="text" class="form-control">
            <div class="input-group-append"><button class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>     
    </div>
    </div>
  </div> -->

<div class="container" style="margin-top: 20px;"  *ngIf="SelectedContent===''">

<div class="row ng-scope">
    <div class="col-md-3 col-md-push-9">
<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
       language
      </mat-panel-title>   
    </mat-expansion-panel-header>

<p>sample </p>

  </mat-expansion-panel>
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Day of the trip
      </mat-panel-title>     
    </mat-expansion-panel-header>
sample filter 2
  </mat-expansion-panel> -->
</mat-accordion>
    </div>
    <div class="col-md-9 col-md-pull-3">
        <!-- <p class="search-results-count">About {{SearchResults.length}} results Found</p> -->
            <section class="search-result-item" *ngFor="let results of SearchResults">
                <div class="search-result-item-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="search-result-item-heading" (click)="LoadContent(results)"><a>{{results?.short_description}}</a></h4>                            
                            <p class="description">{{results?.meta_description}}</p>
                            <div style="display: flex;">                               
                                 <p class="info">{{results?.sys_view_count}} Views</p> <span style="padding-left: 1%; padding-right: 1%;">.</span> <p class="info">{{results?.published}}</p>
                            </div>
                        </div>
                        <!-- <div class="col-sm-2 text-align-center">
                            <p class="value3 mt-sm">{{results?.language}}</p>
                            <p class="fs-mini text-muted">PER WEEK</p><a class="btn btn-primary btn-info btn-sm" href="#">Learn More</a>
                        </div> -->
                    </div>
                </div>
            </section>
        <!-- <div class="text-align-center">   
        </div> -->
    </div>
</div>
</div>

<div class="container" style="margin-top: 20px;" *ngIf="SelectedContent!==''">
  <mat-card><div [innerHTML]="SelectedContent?.text"></div></mat-card>
</div>
