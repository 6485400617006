<!-- Incident Alert  -->
<div class="divider">
  <mat-divider> </mat-divider>
  <marquee width="100%" direction="left" height="20px" style="margin-top: 5px">
    <div>
      <span style="color: red; cursor: pointer" *ngFor="let ticket of IncidentAlerts">
  ({{ticket?.u_incident_priority}}) - {{ticket?.u_email_subject}} | 
        </span>
    </div>    
  </marquee>
</div>
<!-- Incident Alert  -->


<div class="container cont-align mt-1">
  <mat-tab-group mat-align-tabs="center" class="streached">

    <!-- Incidents Card  -->
    <mat-tab label="Ticket">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">build</i>
          <span class="title iconwithtitle ml-2">Incident</span>
        </ng-template>
      </div>

      <ng-template matTabContent>
        <div class="contentdiv">
          <!-- Ticket Block START -->

          <div
            style="
              margin-top: 1%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="float: left; padding-left: 4%">
              <h3>Recent Incidents (Last 90 Days)</h3>
            </div>

            <div
              style="
                float: right;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-right: 4%;
              "
            >
              <div style="width: 100%" class="SearchContentAlign">
                <form class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Incident Number</mat-label>
                    <input
                      type="text"
                      matInput
                      placeholder="Incident Number"
                      #searchTicketText
                    />
                    <mat-icon
                      matSuffix
                      style="cursor: pointer"
                      (click)="searchTicket(searchTicketText.value)"
                      >search</mat-icon
                    >
                  </mat-form-field>
                </form>
                <div
                  *ngIf="searchTicketText !== undefined"
                  class="SearchCancelButton"
                >
                  <button
                    class="btn btn-outline-primary waves-effect"
                    (click)="ResetSearchText()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="divider">
            <mat-divider> </mat-divider>
          </div>

          <section class="wrapper">
            <div class="container">
              <div
                class="text-center flex flex-column justify-content-center align-items-center"
                *ngIf="!isTicketLoading && ticketDatas?.length == 0"
              >
                <div>
                  <!-- <i class="fa fa-exclamation-triangle "  aria-hidden="true" ></i> -->
                  <mat-icon style="transform: scale(1)">info</mat-icon>
                </div>
                <div style="padding-top: 2%">
                  <strong>Ticket Not Found</strong>
                </div>
              </div>
              <div
                class="text-center flex flex-column justify-content-center align-items-center"
                *ngIf="isTicketLoading"
              >
                <div>
                  <mat-spinner [diameter]="40"></mat-spinner>
                </div>
                <div style="padding-top: 3%">
                  <strong>Please Wait ... </strong>
                </div>
              </div>
              <div class="row" *ngIf="!isTicketLoading">
                <!-- | slice:((paginator.pageIndex)*paginator.pageSize):((paginator.pageIndex+1)*paginator.pageSize) -->
                <div
                  class="col-md-4 mt-2"
                  *ngFor="
                    let ticket of ticketDatas
                      | paginate
                        : {
                            itemsPerPage: 6,
                            currentPage: page,
                            totalItems: totallength
                          }
                  "
                >
                  <div
                    class="card text-white card-has-bg click-col"
                    style="background-color: cadetblue"
                  >
                    <div class="card-img-overlay d-flex flex-column">
                      <div class="card-body">
                        <h3
                          class="card-meta mb-2"
                          (click)="redirectToLink('incident', ticket.sys_id)"
                        >
                          {{
                            ticket?.number ? ticket?.number : notAvailableMsg
                          }}
                          -
                          {{
                            ticket?.incident_state
                              ? ticket?.incident_state
                              : notAvailableMsg
                          }}
                        </h3>
                        <h4 class="card-title mt-0">
                          <p class="text-white">
                            {{
                              ticket?.short_description
                                ? ticket?.short_description.length > 50
                                  ? (ticket?.short_description | slice: 0:55) +
                                    ".."
                                  : ticket?.short_description
                                : notAvailableMsg
                            }}
                          </p>
                        </h4>
                        <h4 class="mt-0">
                          <p class="text-white">
                            <i
                              class="fa fa-flag-checkered"
                              aria-hidden="true"
                              style="color: rgb(255, 0, 0); padding-right: 3%"
                            ></i>

                            {{
                              ticket?.priority
                                ? ticket?.priority
                                : notAvailableMsg
                            }}
                          </p>
                        </h4>
                        <h5 class="mt-0">
                          <p class="text-white">
                            Contact Type :
                            {{
                              ticket?.contact_type
                                ? ticket?.contact_type
                                : notAvailableMsg
                            }}
                          </p>
                        </h5>
                        <!-- <h5 class="mt-0">
                          <p
                            class="text-white"
                            (click)="openWorkNotesPopup(ticket?.work_notes)"
                          >
                            Work Notes : Open
                          </p>
                        </h5> -->
                        <h5 class="mt-0">
                          <p class="text-white">
                            Close Code :
                            {{
                              ticket?.close_code
                                ? ticket?.close_code
                                : notAvailableMsg
                            }}
                          </p>
                        </h5>
                        <h5 class="mt-0">
                          <p class="text-white">
                            Location :
                            {{
                              ticket?.location
                                ? ticket?.location
                                : notAvailableMsg
                            }}
                          </p>
                        </h5>
                        <h5 class="mt-0">
                          <p class="text-white">
                            Open at :
                            {{
                              ticket?.opened_at
                                ? ticket?.opened_at
                                : notAvailableMsg
                            }}
                          </p>
                        </h5>

                        <!-- <br>
                          <small
                          >
                          <i class="far fa-clock"></i>
                          {{
                            ticket?.location 
                              ? ticket?.location 
                              : notAvailableMsg
                          }}</small> -->
                      </div>
                      <div class="card-footer">
                        <div class="media">
                          <div class="media-body">
                            <h5 class="my-1 text-white d-block">
                               {{
                            ticket?.assignment_group
                              ? ticket?.assignment_group.length > 24
                                ? (ticket?.assignment_group | slice: 0:24) + ".."
                                : ticket?.assignment_group
                              : notAvailableMsg
                          }}
                            </h5>
                            <h5>
                              {{
                                ticket?.assigned_to
                                  ? ticket?.assigned_to
                                  : notAvailableMsg
                              }}
                            </h5>
                          </div>
                          <h3 (click)="openPopup(ticket,incident)">
                            <i
                              class="far fa-edit"
                              style="padding-right: 10px"
                            ></i
                            >View
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isTicketLoading && ticketDatas?.length > 3">
                <pagination-controls
                  (pageChange)="page = $event"
                  style="display: flex; justify-content: center; margin: 10px"
                ></pagination-controls>
                <!-- <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[3, 6, 9, 12]"
        (page)="pageEvent = $event">
      </mat-paginator> -->
              </div>

              <div style="float: right; margin-top: 20px">
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
              </div>
            </div>
          </section>

          <!-- Ticket Block END -->
        </div>
      </ng-template>
    </mat-tab>

    <!-- Service Requests Card -->
    <mat-tab label="Service Request">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">shopping_cart</i>
          <span class="title iconwithtitle ml-2">Service Request</span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <!-- Service Request Block START -->
        <div
          style="
            margin-top: 1%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="float: left; padding-left: 4%">
            <h3>Recent Service Requests (Last 90 Days)</h3>
          </div>

          <div
            style="
              float: right;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding-right: 4%;
            "
          >
            <div style="width: 100%" class="SearchContentAlign">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>SR Number</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="SR Number"
                    #searchServiceRequestText
                  />

                  <mat-icon
                    matSuffix
                    style="cursor: pointer"
                    (click)="
                      searchServiceRequest(searchServiceRequestText.value)
                    "
                    >search</mat-icon
                  >
                </mat-form-field>
              </form>
              <div
                *ngIf="searchServiceRequestText !== undefined"
                class="SearchCancelButton"
              >
                <button
                  class="btn btn-outline-primary waves-effect"
                  (click)="ResetSearchText()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="divider">
          <mat-divider> </mat-divider>
        </div>
        <section class="wrapper">
          <div class="container">
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="!isSRLoading && serviceRequestDatas?.length == 0"
            >
              <div>
                <!-- <i class="fa fa-exclamation-triangle "  aria-hidden="true" ></i> -->
                <mat-icon style="transform: scale(1)">info</mat-icon>
              </div>
              <div style="padding-top: 2%">
                <strong>Service Request Not Found</strong>
              </div>
            </div>
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="isSRLoading"
            >
              <div>
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div style="padding-top: 3%">
                <strong>Please Wait ... </strong>
              </div>
            </div>
            <div class="row" *ngIf="!isSRLoading">
              <div
                class="col-md-4"
                *ngFor="
                  let serviceRequest of serviceRequestDatas
                    | paginate
                      : {
                          itemsPerPage: 6,
                          currentPage: SRpage,
                          totalItems: ServiceRequesttotallength
                        }
                "
              >
                <div
                  class="card text-white card-has-bg click-col"
                  style="background-color: cadetblue"
                >
                  <div class="card-img-overlay d-flex flex-column">
                    <div class="card-body">
                      <h3 class="card-meta mb-2"  (click)="redirectToLink('serviceRequest', serviceRequest?.number)">
                        {{
                          serviceRequest?.number
                            ? serviceRequest?.number
                            : notAvailableMsg
                        }}
                      </h3>

                      <h3 class="card-meta mb-2">
                        {{
                          serviceRequest?.state
                            ? serviceRequest?.state
                            : notAvailableMsg
                        }}
                      </h3>
                      <h4 class="card-title mt-0">
                        <p class="text-white">
                          {{
                            serviceRequest?.short_description
                              ? serviceRequest?.short_description.length > 50
                                ? (serviceRequest?.short_description
                                    | slice: 0:55) + ".."
                                : serviceRequest?.short_description
                              : notAvailableMsg
                          }}
                        </p>
                      </h4>
                      <h4 class="mt-0">
                        <p class="text-white">
                          <i
                            class="fa fa-flag-checkered"
                            aria-hidden="true"
                            style="color: rgb(255, 0, 0); padding-right: 3%"
                          ></i>

                          {{
                            serviceRequest?.priority
                              ? serviceRequest?.priority
                              : notAvailableMsg
                          }}
                        </p>
                      </h4>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Opened By :
                          {{
                            serviceRequest?.opened_by
                              ? serviceRequest?.opened_by
                              : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Requested For :
                          {{
                            serviceRequest?.u_requested_for
                              ? serviceRequest?.u_requested_for
                              : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Open at :
                          {{
                            serviceRequest?.opened_at
                              ? serviceRequest?.opened_at
                              : notAvailableMsg
                          }}
                        </p>
                      </h5>
                    </div>
                    <div class="card-footer">
                      <div class="media">
                        <div class="media-body">
                          <h5 class="my-1 text-white d-block">
                             {{
                            serviceRequest?.assignment_group
                              ? serviceRequest?.assignment_group.length > 24
                                ? (serviceRequest?.assignment_group | slice: 0:24) + ".."
                                : serviceRequest?.assignment_group
                              : notAvailableMsg
                          }}
                          </h5>
                          <h5>
                            {{
                              serviceRequest?.assigned_to
                                ? serviceRequest?.assigned_to
                                : notAvailableMsg
                            }}
                          </h5>
                        </div>
                        <h3 (click)="OpenServiceRequestPopup(serviceRequest,serviceRequestModal)">
                          <i class="far fa-edit" style="padding-right: 10px"></i
                          >View
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!isSRLoading && serviceRequestDatas?.length > 3">
              <pagination-controls
                (pageChange)="SRpage = $event"
                style="display: flex; justify-content: center; margin: 10px"
              ></pagination-controls>
            </div>

            <div style="float: right; margin-top: 20px"></div>
          </div>
        </section>
        <!-- Service Request Block END -->
      </div>
    </mat-tab>

    <!-- Request Items Card -->
    <mat-tab label="Request Item">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">how_to_vote</i>
          <span class="title iconwithtitle ml-2">Request Item</span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <!-- Request Item Block START -->

        <div
          style="
            margin-top: 1%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="float: left; padding-left: 4%">
            <h3>Recent Request Items (Last 90 Days)</h3>
          </div>

          <div
            style="
              float: right;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding-right: 4%;
            "
          >
            <div style="width: 100%" class="SearchContentAlign">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>RITM Number</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="RITM Number"
                    #requestItemNumber
                  />
                  <mat-icon
                    matSuffix
                    style="cursor: pointer"
                    (click)="searchRequestItem(requestItemNumber.value)"
                    >search</mat-icon
                  >
                </mat-form-field>
              </form>
              <div
                *ngIf="requestItemNumber !== undefined"
                class="SearchCancelButton"
              >
                <button
                  class="btn btn-outline-primary waves-effect"
                  (click)="ResetSearchServiceRequestItemText()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="divider">
          <mat-divider> </mat-divider>
        </div>

        <section class="wrapper">
          <div class="container">
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="!isRequestItemLoading && RequestItemDatas?.length == 0"
            >
              <div>
                <!-- <i class="fa fa-exclamation-triangle "  aria-hidden="true" ></i> -->
                <mat-icon style="transform: scale(1)">info</mat-icon>
              </div>
              <div style="padding-top: 2%">
                <strong>Request Item Not Found</strong>
              </div>
            </div>
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="isRequestItemLoading"
            >
              <div>
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div style="padding-top: 3%">
                <strong>Please Wait ... </strong>
              </div>
            </div>
            <div class="row" *ngIf="!isRequestItemLoading">
              <!-- | slice:((paginator.pageIndex)*paginator.pageSize):((paginator.pageIndex+1)*paginator.pageSize) -->
              <div
                class="col-md-4 mt-2"
                *ngFor="
                  let item of RequestItemDatas
                    | paginate
                      : {
                          itemsPerPage: 6,
                          currentPage: RequestItempage,
                          totalItems: RequestItemtotallength
                        }
                "
              >
                <div
                  class="card text-white card-has-bg click-col"
                  style="background-color: cadetblue"
                >
                  <div class="card-img-overlay d-flex flex-column">
                    <div class="card-body">
                      <h3 class="card-meta mb-2"  (click)="redirectToLink('requestItem', item?.number)">
                        {{ item?.number ? item?.number : notAvailableMsg }}
                      </h3>
                      <h3 class="card-meta mb-2">
                        {{ item?.state ? item?.state : notAvailableMsg }}
                      </h3>

                      <h4 class="card-title mt-0">
                        <p class="text-white">
                          {{
                            item?.short_description
                              ? item?.short_description.length > 50
                                ? (item?.short_description | slice: 0:55) + ".."
                                : item?.short_description
                              : notAvailableMsg
                          }}
                        </p>
                      </h4>
                      <h4 class="mt-0">
                        <p class="text-white">
                          <i
                            class="fa fa-flag-checkered"
                            aria-hidden="true"
                            style="color: rgb(255, 0, 0); padding-right: 3%"
                          ></i>

                          {{
                            item?.priority ? item?.priority : notAvailableMsg
                          }}
                        </p>
                      </h4>

                      <!-- <h5 class="mt-0">
                          <p
                            class="text-white"
                            (click)="openWorkNotesPopup(item?.work_notes)"
                          >
                            Work Notes : Open
                          </p>
                        </h5> -->

                      <h5 class="mt-0">
                        <p class="text-white">
                          Approval :
                          {{
                            item?.approval ? item?.approval : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Stage :
                          {{ item?.stage ? item?.stage : notAvailableMsg }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Open at :
                          {{
                            item?.opened_at ? item?.opened_at : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          SLA Due :
                          {{ item?.sla_due ? item?.sla_due : notAvailableMsg }}
                        </p>
                      </h5>
                    </div>
                    <div class="card-footer">
                      <div class="media">
                        <div class="media-body">
                          <h5 class="my-1 text-white d-block">
                             {{
                            item?.assignment_group
                              ? item?.assignment_group.length > 23
                                ? (item?.assignment_group | slice: 0:23) + ".."
                                : item?.assignment_group
                              : notAvailableMsg
                          }}
                          </h5>
                          <h5>
                            {{
                              item?.assigned_to
                                ? item?.assigned_to
                                : notAvailableMsg
                            }}
                          </h5>
                        </div>
                        <h3 (click)="OpenRequestItemPopup(item,requestItemModal)">
                          <i class="far fa-edit" style="padding-right: 10px"></i
                          >View
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isRequestItemLoading && RequestItemDatas?.length > 3">
              <pagination-controls
                (pageChange)="RequestItempage = $event"
                style="display: flex; justify-content: center; margin: 10px"
              ></pagination-controls>
            </div>

            <div style="float: right; margin-top: 20px">
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
          </div>
        </section>

        <!--Request Item  Block END -->
      </div>
    </mat-tab>

    <!-- Tasks Card -->
    <mat-tab label="Task">
      <div>
        <ng-template mat-tab-label>
          <i class="material-icons">assignment</i>
          <span class="title iconwithtitle ml-2">Task </span>
        </ng-template>
      </div>
      <div class="contentdiv">
        <!-- Task Block START -->

        <div
          style="
            margin-top: 1%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="float: left; padding-left: 4%">
            <h3>Recent Tasks (Last 90 Days)</h3>
          </div>

          <div
            style="
              float: right;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding-right: 4%;
            "
          >
            <div style="width: 100%" class="SearchContentAlign">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Task Number</mat-label>

                  <input
                    type="text"
                    matInput
                    placeholder="Task Number"
                    #taskNumber
                  />
                  <mat-icon
                    matSuffix
                    style="cursor: pointer"
                    (click)="searchUserTask(taskNumber.value)"
                    >search</mat-icon
                  >
                </mat-form-field>
              </form>
              <div *ngIf="taskNumber !== undefined" class="SearchCancelButton">
                <button
                  class="btn btn-outline-primary waves-effect"
                  (click)="ResetSearchUserTaskText()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="divider">
          <mat-divider> </mat-divider>
        </div>

        <section class="wrapper">
          <div class="container">
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="!isTaskLoading && TaskDatas?.length == 0"
            >
              <div>
                <!-- <i class="fa fa-exclamation-triangle "  aria-hidden="true" ></i> -->
                <mat-icon style="transform: scale(1)">info</mat-icon>
              </div>
              <div style="padding-top: 2%">
                <strong>Task Not Found</strong>
              </div>
            </div>
            <div
              class="text-center flex flex-column justify-content-center align-items-center"
              *ngIf="isTaskLoading"
            >
              <div>
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div style="padding-top: 3%">
                <strong>Please Wait ... </strong>
              </div>
            </div>
            <div class="row" *ngIf="!isTaskLoading">
              <!-- | slice:((paginator.pageIndex)*paginator.pageSize):((paginator.pageIndex+1)*paginator.pageSize) -->
              <div
                class="col-md-4 mt-2"
                *ngFor="
                  let task of TaskDatas
                    | paginate
                      : {
                          itemsPerPage: 6,
                          currentPage: TaskItempage,
                          totalItems: Taskstotallength
                        }
                "
              >
                <div
                  class="card text-white card-has-bg click-col"
                  style="background-color: cadetblue"
                >
                  <div class="card-img-overlay d-flex flex-column">
                    <div class="card-body">
                      <h3 class="card-meta mb-2" (click)="redirectToLink('task', task?.number)">
                        {{ task?.number ? task?.number : notAvailableMsg }}
                      </h3>
                      <h3 class="card-meta mb-2">
                        {{ task?.state ? task?.state : notAvailableMsg }}
                      </h3>
                      <h4 class="card-title mt-0">
                        <p class="text-white">
                          {{
                            task?.short_description
                              ? task?.short_description.length > 50
                                ? (task?.short_description | slice: 0:55) + ".."
                                : task?.short_description
                              : notAvailableMsg
                          }}
                        </p>
                      </h4>
                      <h4 class="mt-0">
                        <p class="text-white">
                          <i
                            class="fa fa-flag-checkered"
                            aria-hidden="true"
                            style="color: rgb(255, 0, 0); padding-right: 3%"
                          ></i>

                          {{
                            task?.priority ? task?.priority : notAvailableMsg
                          }}
                        </p>
                      </h4>
                      <h5 class="mt-0">
                        <p
                          class="text-white"
                          >
                          <!-- (click)="openWorkNotesPopup(task?.work_notes)" -->
                          Opened By :
                          {{
                            task?.opened_by ? task?.opened_by : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p
                          class="text-white"
                          >
                          <!-- (click)="openWorkNotesPopup(task?.work_notes)" -->
                          Request Item :
                          {{
                            task?.request_item
                              ? task?.request_item
                              : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p
                          class="text-white"
                          >
                          <!-- (click)="openWorkNotesPopup(task?.work_notes)" -->
                          Approval :
                          {{
                            task?.approval ? task?.approval : notAvailableMsg
                          }}
                        </p>
                      </h5>
                      <h5 class="mt-0">
                        <p class="text-white">
                          Open at :
                          {{
                            task?.opened_at ? task?.opened_at : notAvailableMsg
                          }}
                        </p>
                      </h5>
                    </div>
                    <div class="card-footer">
                      <div class="media">
                        <div class="media-body">
                          <h5 class="my-1 text-white d-block">
                             {{
                           task?.assignment_group
                              ?task?.assignment_group.length > 24
                                ? (task?.assignment_group | slice: 0:24) + ".."
                                :task?.assignment_group
                              : notAvailableMsg
                          }}
                          </h5>
                          <h5>
                            {{
                              task?.assigned_to
                                ? task?.assigned_to
                                : notAvailableMsg
                            }}
                          </h5>
                        </div>
                        <h3 (click)="OpenTaskPopup(task,taskModal)">
                          <i class="far fa-edit" style="padding-right: 10px"></i
                          >View
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isTaskLoading && TaskDatas?.length > 3">
              <pagination-controls
                (pageChange)="TaskItempage = $event"
                style="display: flex; justify-content: center; margin: 10px"
              ></pagination-controls>
            </div>

            <div style="float: right; margin-top: 20px">
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
          </div>
        </section>

        <!--Task Block END -->
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<!-- <div style="
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  ">
  <div style="float: left; padding-left: 4%">
    <h3>Auto Heal Device</h3>
  </div>
</div> -->
<!-- <div class="divider">
  <mat-divider> </mat-divider>
</div> -->

<!-- <section class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-xl-4" *ngFor="let device of DeviceDatas">
        <div class="device-fix-card">
          <div class="device-fix-card-body">
            <div class="media align-items-center">
              <i class="material-icons mr-3"
                style="color: black; width: 10%; font-size: 30px !important">laptop_chromebook</i>
              <div class="media-body overflow-hidden">
                <h4 class="card-text mb-0">{{ device?.name }}</h4>
                <p class="card-text text-uppercase text-muted">
                  {{ device?.model_id }}
                </p>
              </div>
              <button class="btn btn-outline-primary waves-effect" (click)="openFixPrompt(device)">
                Fix Device
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="device-fix-card">
          <div class="device-fix-card-body">
            <div class="media align-items-center">
              <i
                class="material-icons mr-3"
                style="color: black; width: 10%; font-size: 30px !important"
                >laptop_chromebook</i
              >
              <div class="media-body overflow-hidden">
                <h4 class="card-text mb-0">Device Name</h4>
                <p class="card-text text-uppercase text-muted">Device ID</p>
             
              </div>
              <button class="btn btn-outline-primary waves-effect" (click)="openFixPrompt(device)">
               
                Fix Device
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- Modals START -->
<!-- Incident Modal -->
<ng-template #incident let-modal>
<div>
  <div
    class="modal-full-height modal-xl modal-right modal-dialog-centered modal-notify modal-info"
    role="document"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header" >
        <div class="flex flex-row" >
          <div class="heading lead">
            <i class="far fa-file-alt" style="padding-right: 10px"> </i>
            {{ modalContent?.number }}
          </div>
        </div>
        <div>
        <button  class=" btn btn-showDetails-primary waves-effect" (click)="redirectToLink('incident', modalContent?.sys_id)">Show details</button>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.close('Close click')"
          >
            <span aria-hidden="true" class="white-text">×</span>
          </button>
        </div>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Number:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.number
                      ? modalContent?.number
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
              <div class="form-group row">
              <label class="col-4 col-form-label">Contact type:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.contact_type
                      ? modalContent?.contact_type
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

          </div>
          <div class="col">
              <div class="form-group row">
              <label class="col-4 col-form-label">Opened:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.opened_at
                      ? modalContent?.opened_at
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
        
            <div class="form-group row">
              <label class="col-4 col-form-label">Incident State:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.incident_state ? modalContent?.incident_state : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
              <div class="form-group row">
              <label class="col-4 col-form-label">Caller :</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.caller_id
                      ? modalContent?.caller_id
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
       <div class="form-group row">
              <label class="col-4 col-form-label">Opened For:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.u_opened_for
                      ? modalContent?.u_opened_for
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
        
          </div>
          <div class="col">
           <div class="form-group row">
              <label class="col-4 col-form-label">Category:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.category
                      ? modalContent?.category
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
 <div class="form-group row">
              <label class="col-4 col-form-label">Sub category:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.subcategory
                      ? modalContent?.subcategory
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
             <div class="row">
          <div class="col">
                <div class="form-group row">
              <label class="col-4 col-form-label">Location:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.location
                      ? modalContent?.location
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
                <div class="form-group row">
              <label class="col-4 col-form-label">Impact:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.impact
                      ? modalContent?.impact
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
              
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Assignment Group:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.assignment_group
                      ? modalContent?.assignment_group
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Assigned to:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.assigned_to
                      ? modalContent?.assigned_to
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Urgency:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.urgency
                      ? modalContent?.urgency
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>              
            <div class="form-group row">
              <label class="col-4 col-form-label">Priority:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.priority
                      ? modalContent?.priority
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          
          </div>
          <div class="col">
                         <div class="form-group row">
              <label class="col-4 col-form-label">Contact Language:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.u_contact_language
                      ? modalContent?.u_contact_language
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
                  <div class="form-group row">
              <label class="col-4 col-form-label">Translation Needed :</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.u_translation_needed
                      ? modalContent?.u_translation_needed
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

          </div>
        </div>

             <div class="row">
          <div class="col">
                     <div class="form-group row">
              <label class="col-4 col-form-label">Reference code:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.u_reference_code  
                      ? modalContent?.u_reference_code  
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>               
          </div>
          <div class="col">
                         <div class="form-group row">
              <label class="col-4 col-form-label">Configuration Item:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.cmdb_ci
                      ? modalContent?.cmdb_ci
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-2 col-form-label">Short description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.short_description
                      ? modalContent?.short_description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.description
                      ? modalContent?.description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Close notes:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    modalContent?.close_notes
                      ? modalContent?.close_notes
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
         <!-- <button type="button" class="btn btn-light" >Close</button> -->
        <a
          type="button"
          class="btn btn-outline-primary waves-effect"
          data-dismiss="modal"
          (click)="modal.close('Close click')"
          >Exit</a
          >
          <!-- (click)="closePopup()" -->
      </div>
    </div>
  </div>
</div>
</ng-template>

<!-- Service Request Modal -->
<ng-template #serviceRequestModal let-modal>
<div
>
  <div
    class=" modal-full-height modal-xl modal-right modal-dialog-centered modal-notify modal-info"
    role="document"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
          <div class="flex flex-row" >
            <div class="heading lead">
              <i class="far fa-file-alt" style="padding-right: 10px"> </i>
              {{ ServiceRequestModalContent?.number }}
            </div>
        </div>

        <div>
        <button  class=" btn btn-showDetails-primary waves-effect" (click)="redirectToLink('serviceRequest', ServiceRequestModalContent?.sys_id)">Show details</button>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
         (click)="modal.close('Close click')"
        >
          <span aria-hidden="true" class="white-text">×</span>
        </button>
        </div>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Number:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.number
                      ? ServiceRequestModalContent?.number
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Opened by:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.opened_by
                      ? ServiceRequestModalContent?.opened_by
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Opened:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.opened_at
                      ? ServiceRequestModalContent?.opened_at
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">SLA due:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.sla_due
                      ? ServiceRequestModalContent?.sla_due
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Request for:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.u_requested_for
                      ? ServiceRequestModalContent?.u_requested_for
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Impact:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.impact
                      ? ServiceRequestModalContent?.impact
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">State:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.state
                      ? ServiceRequestModalContent?.state
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Configuration item:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.cmdb_ci
                      ? ServiceRequestModalContent?.cmdb_ci
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Urgency:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.urgency
                      ? ServiceRequestModalContent?.urgency
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Priority:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.priority
                      ? ServiceRequestModalContent?.priority
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Assignment group:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.assignment_group
                      ? ServiceRequestModalContent?.assignment_group
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Assigned to:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.assigned_to
                      ? ServiceRequestModalContent?.assigned_to
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-2 col-form-label">Short description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.short_description
                      ? ServiceRequestModalContent?.short_description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.description
                      ? ServiceRequestModalContent?.description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-2 col-form-label">Close notes:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    ServiceRequestModalContent?.close_notes
                      ? ServiceRequestModalContent?.close_notes
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a
          type="button"
          class="btn btn-outline-primary waves-effect"
          data-dismiss="modal"
          (click)="modal.close('Close click')"
          >Exit</a
        >
      </div>
    </div>
  </div>
</div>
</ng-template>

<!-- Request Item Modal -->
<ng-template #requestItemModal let-modal>
<div>
  <div
    class=" modal-full-height modal-xl modal-right modal-dialog-centered modal-notify modal-info"
    role="document"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="flex flex-row" >
        <div class="heading lead">
          <i class="far fa-file-alt" style="padding-right: 10px"> </i>
          {{ RequestItemModalContent?.number }}
        </div>
        </div>

        <div>
        <button  class=" btn btn-showDetails-primary waves-effect" (click)="redirectToLink('requestItem', RequestItemModalContent?.sys_id)">Show details</button>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.close('Close click')"
          >
            <span aria-hidden="true" class="white-text">×</span>
          </button>
        </div>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Number:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.number
                      ? RequestItemModalContent?.number
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Openedby:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.opened_by
                      ? RequestItemModalContent?.opened_by
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Opened:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.opened_at
                      ? RequestItemModalContent?.opened_at
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Approval:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.approval
                      ? RequestItemModalContent?.approval
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Location:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.location
                      ? RequestItemModalContent?.location
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Stage:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.stage
                      ? RequestItemModalContent?.stage
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">SLA due:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.sla_due
                      ? RequestItemModalContent?.sla_due
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">State:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.state
                      ? RequestItemModalContent?.state
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Order Guide:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.order_guide
                      ? RequestItemModalContent?.order_guide
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Item :</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.cat_item
                      ? RequestItemModalContent?.cat_item
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Configuration item:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.configuration_item
                      ? RequestItemModalContent?.configuration_item
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Assigned group:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.assignment_group
                      ? RequestItemModalContent?.assignment_group
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-2 col-form-label">Short description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.short_description
                      ? RequestItemModalContent?.short_description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.description
                      ? RequestItemModalContent?.description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Close notes:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    RequestItemModalContent?.close_notes
                      ? RequestItemModalContent?.close_notes
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="RequestItemModalContent?.incident_state != 'Closed'">
          <hr />
          <p class="text-center">
            <strong>Update Worknotes</strong>
          </p>

          <div class="md-form">
            <textarea
              type="text"
              id="form79textarea"
              class="md-textarea form-control"
              rows="3"
              value="{{ modalContent?.work_notes }}"
            ></textarea>
          </div>
        </div> -->
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a
          type="button"
          class="btn btn-outline-primary waves-effect"
          data-dismiss="modal"
         (click)="modal.close('Close click')"
          >Exit</a
        >
      </div>
    </div>
  </div>
</div>
</ng-template>

<!-- Task Modal -->
<ng-template #taskModal let-modal>
<div
>
  <div
    class=" modal-full-height modal-xl modal-right modal-dialog-centered modal-notify modal-info"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="flex flex-row" >
        <div class="heading lead">
          <i class="far fa-file-alt" style="padding-right: 10px"> </i>
          {{ TaskModalContent?.number }}
        </div>
        </div>
<div>
          <button  class=" btn btn-showDetails-primary waves-effect" (click)="redirectToLink('task', TaskModalContent?.sys_id)">Show details</button>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
     (click)="modal.close('Close click')"
  >
    <span aria-hidden="true" class="white-text">×</span>
  </button>
</div>
      </div>



      <!--Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Number:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.number
                      ? TaskModalContent?.number
                      : notAvailableMsg
                  }}
                </p>
                <!-- <input type="text" readonly id="Number" class="form-control-plaintext" value="TaskModalContent?.number ? TaskModalContent?.number : notAvailableMsg"> -->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Openedby:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.opened_by
                      ? TaskModalContent?.opened_by
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Request Item:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.request_item
                      ? TaskModalContent?.request_item
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Opened:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.opened_at
                      ? TaskModalContent?.opened_at
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Location:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.location
                      ? TaskModalContent?.location
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">SLA due:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.sla_due
                      ? TaskModalContent?.sla_due
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Approval:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.approval
                      ? TaskModalContent?.approval
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">State:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.state
                      ? TaskModalContent?.state
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Request:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.request
                      ? TaskModalContent?.request
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">Configuration item:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.cmdb_ci
                      ? TaskModalContent?.cmdb_ci
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row">
              <label class="col-4 col-form-label">Assignment group:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.assignment_group
                      ? TaskModalContent?.assignment_group
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Assigned to:</label>
              <div class="col-8">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.assigned_to
                      ? TaskModalContent?.assigned_to
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group row">
              <label class="col-2 col-form-label">Short description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.short_description
                      ? TaskModalContent?.short_description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Description:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.description
                      ? TaskModalContent?.description
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2 col-form-label">Close notes:</label>
              <div class="col-10">
                <p class="mb-0 form-control-plaintext">
                  {{
                    TaskModalContent?.close_notes
                      ? TaskModalContent?.close_notes
                      : notAvailableMsg
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a
          type="button"
          class="btn btn-outline-primary waves-effect"
          data-dismiss="modal"
         (click)="modal.close('Close click')"
          >Exit</a
        >
      </div>
    </div>
  </div>
</div>
</ng-template>

<!-- Fix Device Modal -->
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: FixDevicedisplayStyle }"
>
  <div
    class=" modal-full-height modal-md modal-right modal-dialog-centered modal-notify modal-info"
    role="document"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">
          <i class="far fa-file-alt" style="padding-right: 10px"> </i>
          Fix Device - {{ FixDeviceModalContent?.name }}
        </p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFixPrompt()"
        >
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div
          class="modal-body-content"
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
          "
        >
          <div>
            <form>
              <mat-form-field appearance="fill">
                <mat-label>Select Issue</mat-label>
                <mat-select name="Issues">
                  <!-- [(ngModel)]="selectedValue" -->
                  <mat-option
                    *ngFor="let Issues of IssueDetails"
                    [value]="Issues.value"
                  >
                    {{ Issues.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </div>

          <div style="padding-bottom: 1rem">
            <a
              type="button"
              class="btn btn-outline-primary waves-effect"
              data-dismiss="modal"
              (click)="closeFixPrompt()"
              >Apply Fix</a
            >
          </div>
        </div>
        <!--Footer-->
        <!-- <div class="modal-footer justify-content-center">
          <a
            type="button"
            class="btn btn-outline-primary waves-effect"
            data-dismiss="modal"
            (click)="closeFixPrompt()"
            >Cancel</a
          >
          
          >
        </div> -->
      </div>
    </div>
  </div>
</div>
<!-- Alert Modal  -->
<!-- Incident Alert Modal -->
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayAlertStyle }"
>
  <!-- [ngStyle]="{ display: 'block' }" -->
  <div
    class=" modal-full-height modal-lg modal-right modal-dialog-centered modal-notify modal-info"
    role="document"
  >
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">
          <i class="far fa-file-alt" style="padding-right: 10px"> </i>
          {{ AlertmodalContent?.number }}
        </p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          >
          <!-- (click)="closeAlertPopup()" -->
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Event Type:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.event_type
                  ? AlertmodalContent?.event_type
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Severity:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.severity
                  ? AlertmodalContent?.severity
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Opened At:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.opened_at
                  ? AlertmodalContent?.opened_at
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Closed At:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.closed_at
                  ? AlertmodalContent?.closed_at
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Disruption Time:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.actual_disruption_time
                  ? AlertmodalContent?.actual_disruption_time
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Assignment Group:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.assignment_group
                  ? AlertmodalContent?.assignment_group
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Resolution Code:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.resolution_code
                  ? AlertmodalContent?.resolution_code
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Resolution Notes:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.resolution_notes
                  ? AlertmodalContent?.resolution_notes
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Resolved At:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.resolved_at
                  ? AlertmodalContent?.resolved_at
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Incident:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.source_incident
                  ? AlertmodalContent?.source_incident
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-body-content">
          <div class="modal-body-content-label">
            <p><strong>Created on:</strong></p>
            <p class="modal-body-content-value">
              {{
                AlertmodalContent?.sys_created_on
                  ? AlertmodalContent?.sys_created_on
                  : notAvailableMsg
              }}
            </p>
          </div>
          <div class="modal-body-content-label">
            <p><strong>Priority:</strong></p>
            <p class="modal-body-content-value">
              -
              {{
                AlertmodalContent?.priority
                  ? AlertmodalContent?.priority
                  : notAvailableMsg
              }}
            </p>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <a
            type="button"
            class="btn btn-outline-primary waves-effect"
            data-dismiss="modal"
            >Cancel</a
            >
            <!-- (click)="closeAlertPopup()" -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modals END  -->
