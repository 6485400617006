import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserpersonaService } from 'src/app/service/user/userpersona.service';
import { Router } from '@angular/router';
import { OktaService } from 'src/app/service/okta/okta.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/service/auth/auth-service.service';
import { SupportpersonaService } from 'src/app/service/support/supportpersona.service';
import { DevicepersonaService } from 'src/app/service/device/devicepersona.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  notAvailableMsg = '-';
  UserProfilePic: any = null;
  aadUserinfo: any = null;

  IncidentAlertDatas: any;
  BadgeLength: number = 0;
  AlertmodalContent: any;
  searchUserid = '';
  ElicenseDetails: any;
  PowerBilicenseDetails: any;
  AllLicenseDetails: any;
  Ldetails: any = {
    name: null,
    enabled: [],
    disabled: [],
  };
  LicenseDetailsAll: any = [];
  isEmailMisMatch: boolean = false;
  UserLogs: any = [];
  constructor(
    private route: ActivatedRoute,
    private _userpersona: UserpersonaService,
    private _supportpersona: SupportpersonaService,
    private _devicePersona: DevicepersonaService,
    private _oktaService: OktaService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    config: NgbRatingConfig
  ) {
    config.max = 4;
    config.readonly = true;
    
  }
  bgcolor = '#0F059E';
  phoneani: any;
  sortedArray: any = [];
  userInfo: any;
  sam: any;
  userId: any;
  OktaUserGrpResponse: any;
  OktaUserInfo: any;
  loadingUserGrp: boolean = false;
  isLoading: boolean = false;
  isOktaLogsLoading: boolean = false;
  sortedGroup: any = [];
  searchText = '';

    zoomphone:any= false;
   customerSatifaction:any;
   chart5data:any=[0,0,0,0];
   chart5:any={
      key:[],
      value:[]
   }
   starValue:any={
    "totalCount":0,
    "totalScore":0
   }
   starTotalCount:any=0;
   starScoreCount:any=0;
   finalStartRating:any;
   isRating:any=false
  boxSite: any = [
    'Barrow Mill',
    'Northfleet Mill',
    'Jaromer Mill',
    'Chorley Distribution Centre',
    'Parcolog - VSE (Norbert Dentressangle)',
    'Villey-Saint-Etienne (VSE) Mill',
    'Koblenz Mill',
    'Romagnano Mill',
    'Flint Site',
  ];
  ngOnInit(): void {
    this.sam = true;
    this.userInfo = '';
    try {
      this.route.queryParams.subscribe((params) => {
        console.log(
          'UserComponent ~ this.route.queryParams.subscribe ~ params',
          params
        );
        // check authToken from Params
        if (params?.hasOwnProperty('authToken')) {
          let authToken = sessionStorage.getItem('authToken');
          let accesstoken = sessionStorage.getItem('accessToken');
          
    
          //console.log('UserComponent ~ ngOnInit ~ authToken', authToken);
          //console.log('UserComponent ~ ngOnInit ~ accesstoken', accesstoken);
          sessionStorage.setItem('authToken', params?.authToken);
          // Fetch Auth-login Info - AccessToken not exist
          if (!accesstoken) {
           // console.log('Fetch Auth-login Info');
            this.fetchLoginInfo(params?.authToken);
          }
          // Fetch details - AccessToken exist and check expiry
          // this.authService.AuthExpiryCheck(Expiry);
          if (accesstoken) {
        this.fetchUserDetails(accesstoken,params);
        
          }
        } else {
          // clear session aand redirecting to error page
          this.clearUserSession();
          this._router.navigate(['/', 'error']);
        }
 
      });
    } catch (e) {
      this.isOktaLogsLoading = false;
      this.loadingUserGrp = false;      
      //console.log('catch error', e);
      //clear session and redirecting to error page
      this.clearUserSession();
      this._router.navigate(['/', 'error']);
    }
  }

fetchUserDetails(accessToken:any,params:any){

try {
  let phoneAni = sessionStorage.getItem('phoneaniLocal');
          console.log("UserComponent ~ this.route.queryParams.subscribe ~ phoneAni", phoneAni)
          // console.log("UserComponent ~ this.route.queryParams.subscribe ~ phoneAni- type",typeof(phoneAni))
      this._userpersona.globalOktaAccessToken = accessToken;            
            //Service Now IncidentAlert
            this._userpersona.getIncidentAlert().subscribe(
              (Alertresponse: any) => {
                this.BadgeLength = Alertresponse?.data?.length;
                this.IncidentAlertDatas = Alertresponse?.data;
                this._userpersona.incidentAlerts = Alertresponse?.data;
                // console.log('Alertresponse', Alertresponse);
              },
              (error) => {
                console.log('error', error);
              }
            );

            // this.fetchLoginInfo(params.authToken);
            if (this.checkPhoneAni(phoneAni)) {
              if (Object.keys(params).length != 0) {
                this.phoneani = phoneAni;
                this.phoneani = this.phoneani.replace('+', '');
                let phoneAniTrim= this.phoneani.trim();
                console.log(phoneAniTrim + '|aaa');

                this._userpersona.getUserInfo(phoneAniTrim).subscribe(
                  (response: any) => {
                    if (response.data.length != 0) {
                      //changing phone number to userid in router
                      this._router.navigate([], {
                        queryParams: {
                          phoneani: null,
                          Userid: response.data[0].user_name,
                        },
                        queryParamsHandling: 'merge',
                      });

                      //Service Now
                      this.userInfo = response.data[0];
                      // console.log(response.data[0].u_ou.split(',')[2].split("=")[1])
                      this.userInfo.country = response.data[0]?.u_ou
                        .split(',')[2]
                        .split('=')[1];
                      this.userInfo.u_ou = response.data[0]?.u_ou
                        .split(',')[3]
                        .split('=')[1];

                      const found = this.boxSite.find((e: any) => {
                        // console.log('ekl', this.userInfo.location);
                        if (
                          e.toLowerCase() ===
                          this.userInfo.location.toLowerCase()
                        ) {
                          // if(found){
                          Swal.fire({
                            text: 'User is from Box Site',
                            icon: 'warning',
                          });
                          // }
                        }
                      });
                      this._userpersona.globalUserid = response.data[0].sys_id;
                      this._userpersona.globalUsername =
                        response.data[0].user_name;
                      this._userpersona.globalUserEmail =
                        response.data[0].email;
                      this._userpersona.servicenowUserInfo = response.data[0]; //saving service now user info globally
                      // console.log('Saved service now detilas globlly');
                      // Resetting data Start
                      this._devicePersona.ResetDevicePersona();
                      this._oktaService.ResetOktaMFA();
                      // Resetting data End
                      this.getLicenseDetails();
                      this.getUserProfilePic();
                      this.getUserAADinfo();
                      this.CustomerSatification();
                      /*-----------------------------Okta API---------------------------- */
                      //Get user info from okta using mail
                      this._oktaService
                        .getUserInfoFromOkta(
                          this.userInfo.email,
                          this._userpersona.globalOktaAccessToken
                        )
                        .subscribe(
                          (OktaUserResponse: any) => {
                            // console.log('Okta User Info', OktaUserResponse);
                            if (OktaUserResponse.http_status_code == 200) {
                              this._userpersona.oktaUserInfo =
                                OktaUserResponse.data[0];
                              this.OktaUserInfo = OktaUserResponse.data[0];
                              this.MatchUserEmail(
                                this.userInfo.email,
                                OktaUserResponse?.data[0]?.profile?.email
                              );
                              this._userpersona.oktaUserInfo =
                                OktaUserResponse.data[0]; //saving okta user info globally
                              // console.log('Saved okta User detilas globlly');

                              //Get user group from okta using mail
                              this._oktaService
                                .getUserGroupFromOkta(
                                  this._userpersona.oktaUserInfo.id,
                                  this._userpersona.globalOktaAccessToken
                                )
                                .subscribe(
                                  (OktaUserGrpResponse: any) => {
                                    // console.log(
                                    //   'OktaUserResponse Info group',
                                    //   OktaUserGrpResponse
                                    // );
                                    if (
                                      OktaUserGrpResponse.http_status_code ==
                                      200
                                    ) {
                                      this.OktaUserGrpResponse =
                                        OktaUserGrpResponse.data;
                                      //  this.OktaUserGrpResponse= OktaUserGrpResponse.data

                                      for (let a of this.OktaUserGrpResponse) {
                                        this.sortedArray.push(a.profile.name);
                                      }
                                      this.sortedGroup =
                                        this.sortedArray.sort();
                                      //saved group membership globalluy
                                      this._userpersona.oktaGroupmembership =
                                        this.sortedGroup;

                                      // console.log('Saved okta groupmembership');
                                      /* Internet and mail box */
                                    
                                      const zoomphone = this.sortedGroup?.includes(
                                        'Zoom Phone'
                                      );
      
                                      if (zoomphone) {
                                        this.zoomphone = true;
                                      } else {
                                        this.zoomphone = false;
                                      }

                                    this._userpersona.buttondetails=this.zoomphone;
                                    }
                                  },
                                  (error) => {
                                    console.log('error', error);
                                  }
                                );

                              this.getUserLogs();
                            }
                            if (OktaUserResponse.http_status_code == 401) {
                              this.clearUserSession();
                              this._router.navigate(['/', 'sessionTimeout']);
                            }
                          },
                          (error) => {
                            console.log('error', error);
                          }
                        );
                    } else {
                      this.userInfo = null;
                      this.OktaUserInfo = null;
                      // console.log(
                      //   this._userpersona.servicenowUserInfo + 'sdafdsaf'
                      // );
                      Swal.fire({
                        text: 'User not found with the Phone Number. Use Search to find the User ',
                        icon: 'error',
                      });
                    }
                  },
                  (error) => {
                    this.userInfo = '';
                    console.log('error');
                  }
                );
              }
            } else if (params?.Userid) {
              // console.log(this._userpersona.servicenowUserInfo);
              if (this._userpersona.servicenowUserInfo != undefined) {
                this.userInfo = this._userpersona.servicenowUserInfo;
                this.OktaUserInfo = this._userpersona.oktaUserInfo;
                this.sortedGroup = this._userpersona.oktaGroupmembership;
                this.aadUserinfo = this._userpersona.globalUserAAD;
                this.zoomphone=this._userpersona.buttondetails;
                this.UserLogs = this._userpersona.globalUserLogs;
                this.AllLicenseDetails = this._userpersona?.globalLicense;
                if(this._userpersona.finalStartRating>0){
                  this.finalStartRating=this._userpersona.finalStartRating;
                  this.isRating=true
                }
                console.log("helo ~  this.finalStartRating",  this.finalStartRating)
                this.UserProfilePic = this._userpersona.globalUserProfilePic;
                if (this.AllLicenseDetails) {
                  this.AllLicenseDetails.forEach((sam: any, index: any) => {
                    this.Ldetails['name'] = sam?.skuPartNumber;
                    for (let plan of sam.servicePlans) {
                      if (plan.provisioningStatus === 'Success') {
                        this.Ldetails.enabled.push(plan.servicePlanName);
                      } else {
                        this.Ldetails.disabled.push(plan.servicePlanName);
                      }
                    }
                    this.Ldetails.enabled.sort();

                    this.Ldetails.disabled.sort();
                    this.LicenseDetailsAll.push(this.Ldetails);
                    this.Ldetails = {
                      name: null,
                      enabled: [],
                      disabled: [],
                    };
                  });
                }
                this.OktaUserGrpResponse =
                  this._userpersona.oktaGroupmembership;
              
              }
            }
} catch (error) {
  console.log('catch error', error);
  
}

  
}

  private fetchLoginInfo(authToken: string) {
    // alert('received token from okta' + authToken);
   
    this.authService.fetchAuthInfo(authToken).subscribe((data: any) => {
      // console.log('data', data);
      // Check admin
      if (data?.responseDetails[0]?.statusCode === '200') {
        this._userpersona.globalOktaAccessToken =
          data?.responseDetails[0]?.accessToken;
        sessionStorage.setItem(
          'accessToken',
          data?.responseDetails[0]?.accessToken
        );  
        let phoneAni = sessionStorage.getItem('phoneaniLocal');
        this.fetchUserDetails(data?.responseDetails[0]?.accessToken,{phoneani:"1"});
        //set AccessToken Expiry
        //  sessionStorage.setItem(
        //   'Expiry',
        //   data?.responseDetails[0]?
        // );
      } else {
        // clear session and redirecting to error page
        this.clearUserSession();
        this._router.navigate(['/', 'authError']);
      }

      //alert('here' + JSON.stringify(data[0].accessToken));
      console.log('[fetchAuthInfo]: Success!!');
      // console.log(data?.responseDetails[0]?.accessToken);
      // this.userName = data["responseDetails"][0].userId;
    });
  }

// Check PhoneAni
checkPhoneAni(phoneAni:any){
   return (phoneAni===undefined || phoneAni==="undefined" || phoneAni==="null" || phoneAni===null) ? false : true

}

  // Clear Session Storage
  clearUserSession() {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('accessToken');
  }

  // get User License Details
  getLicenseDetails() {
    this.LicenseDetailsAll = [];
    this._userpersona
      .getLicenseDetails(this._userpersona.globalUserEmail)
      .subscribe(
        (response: any) => {
          this.AllLicenseDetails = response.data.value;
          this._userpersona.globalLicense = this.AllLicenseDetails;
          // console.log(this.AllLicenseDetails + 'Sssssssssssss');
          if (this.AllLicenseDetails) {
            this.AllLicenseDetails.forEach((sam: any, index: any) => {
              this.Ldetails['name'] = sam?.skuPartNumber;
              for (let plan of sam.servicePlans) {
                if (plan.provisioningStatus === 'Success') {
                  this.Ldetails.enabled.push(plan.servicePlanName);
                } else {
                  this.Ldetails.disabled.push(plan.servicePlanName);
                }
              }
              this.Ldetails.enabled.sort();
              this.Ldetails.disabled.sort();
              this.LicenseDetailsAll.push(this.Ldetails);
              this.Ldetails = {
                name: null,
                enabled: [],
                disabled: [],
              };
            });
          }
        },
        (error) => {
          console.log('error', error);
        }
      );
  }

  handleSubmit(e: any) {
    e.preventDefault();
    // alert(this.searchUserid);
  }

  // Search Keyup
  handleKeyUp(e: any) {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  }

  // Search User by User-id
  searchuserbyUserid(e: any) {
    let searchText = e.target.value.trim();
    if (searchText) {
      try {
        // Resetting data Start
                
        this._devicePersona.ResetDevicePersona();
        this._oktaService.ResetOktaMFA();
        this._userpersona.resetOktaLogs();
        // Resetting data End
        this._userpersona.getUserbyUserid(searchText).subscribe(
          (response: any) => {
            // console.log(JSON.stringify(response.data.length) + 'dsf');
            if (response.data.length != 0) {
              sessionStorage.removeItem("phoneaniLocal");
              this._router.navigate([], {
                queryParams: {
                  phoneani: null,
                  Userid: response.data[0].user_name,
                },
                queryParamsHandling: 'merge',
              });
              //Clear stored data from support tab
              this._supportpersona.ticketDatas = null;
              this._supportpersona.RequestItemDatas = null;
              this._supportpersona.serviceRequestDatas = null;
              this._supportpersona.TaskDatas = null;
              this._supportpersona.TaskDatas = null;
              //clear stored data from device tab
              this._devicePersona.devicePersona.assetDetails = null;
              this._devicePersona.devicePersona.asset = null;
              this._userpersona.buttondetails=null
              // console.log(response)
              // this._router.navigate([], {
              //   queryParams: {
              //     phoneani: response.data[0].phone
              //   },
              //   queryParamsHandling: 'merge',
              // });
              if (response.data.length != 0) {
                e.target.value = '';
                //    this._userpersona
                // .getIncidentAlert()
                // .subscribe(
                //   (Alertresponse: any) => {
                //     this.BadgeLength=Alertresponse?.data?.length
                //     this.IncidentAlertDatas=Alertresponse?.data
                //     console.log('Alertresponse', Alertresponse);
                //   },
                //   (error) => {
                //     console.log('error', error);
                //   }
                // );

                this.userInfo = response.data[0];
                // console.log(response.data[0].u_ou.split(',')[2].split("=")[1])
                this.userInfo.country = response.data[0]?.u_ou
                  .split(',')[2]
                  .split('=')[1];
                this.userInfo.u_ou = response.data[0]?.u_ou
                  .split(',')[3]
                  .split('=')[1];

                const found = this.boxSite.find((e: any) => {
                  // console.log('ekl', this.userInfo.location);
                  if (
                    e.toLowerCase() === this.userInfo.location.toLowerCase()
                  ) {
                    // if(found){
                    Swal.fire({
                      text: 'User is from Box Site',
                      icon: 'warning',
                    });
                    // }
                  }
                });
                this._userpersona.globalUserid = response.data[0].sys_id;
                this._userpersona.globalUsername = response.data[0].user_name;
                this._userpersona.globalUserEmail = response.data[0].email;

                this._userpersona.servicenowUserInfo = response.data[0]; //saving service now user info globally
                // console.log('Saved service now detilas globlly');
                this.getUserProfilePic();
                this.getUserAADinfo();
                this.CustomerSatification();
                this._oktaService
                  .getUserInfoFromOkta(
                    this.userInfo.email,
                    this._userpersona.globalOktaAccessToken
                  )
                  .subscribe(
                    (OktaUserResponse: any) => {
                      // console.log('Okta User Info', OktaUserResponse);
                      if (OktaUserResponse.http_status_code == 200) {
                        this._userpersona.oktaUserInfo =
                          OktaUserResponse.data[0];
                        this.OktaUserInfo = OktaUserResponse.data[0];
                        this.MatchUserEmail(
                          this.userInfo.email,
                          OktaUserResponse?.data[0]?.profile?.email
                        );

                        this._userpersona.oktaUserInfo =
                          OktaUserResponse.data[0]; //saving okta user info globally
                        // console.log('Saved service now detilas globlly');
                        this.getLicenseDetails();
                       
                        this._oktaService
                          .getUserGroupFromOkta(
                            this._userpersona.oktaUserInfo.id,
                            this._userpersona.globalOktaAccessToken
                          )
                          .subscribe(
                            (OktaUserGrpResponse: any) => {
                              // console.log(
                              //   'OktaUserResponse Info group',
                              //   OktaUserGrpResponse
                              // );
                              // this.loadingUserGrp = true;

                              if (OktaUserGrpResponse.http_status_code == 200) {
                                this.OktaUserGrpResponse =
                                  OktaUserGrpResponse.data;
                                //  this.OktaUserGrpResponse= OktaUserGrpResponse.data

                                let sortedArray = [];
                                for (let a of this.OktaUserGrpResponse) {
                                  sortedArray.push(a.profile.name);
                                }

                                this.sortedGroup = sortedArray.sort();
                                this._userpersona.oktaGroupmembership =
                                  this.sortedGroup;
                                // console.log('Saved okta groupmembership');
                                const zoomphone = this.sortedGroup?.includes(
                                  'Zoom Phone'
                                );

                                if (zoomphone) {
                                  this.zoomphone = true;
                                } else {
                                  this.zoomphone = false;
                                }

                              this._userpersona.buttondetails=this.zoomphone;


                                this.getUserLogs();
                                // console.log(this.OktaUserGrpResponse);
                              }
                              this.loadingUserGrp = false;
                            },
                            (error) => {
                              this.loadingUserGrp = false;
                              console.log('error', error);
                            }
                          );
                      }
                      if (OktaUserResponse.http_status_code == 401) {
                        this.clearUserSession();
                        this._router.navigate(['/', 'sessionTimeout']);
                      }
                    },
                    (error) => {
                      console.log('error', error);
                    }
                  );
              } else {
                e.target.value = '';
                Swal.fire({
                  text: 'User not found. ',
                  icon: 'error',
                });
              }
            } else {
              this._router.navigate([], {
                queryParams: {
                  phoneani: null,
                  Userid: null,
                },
                queryParamsHandling: 'merge',
              });

              this.userInfo = null;
              this.OktaUserInfo = null;
              this.UserProfilePic =null;
              this.aadUserinfo=null;
              this.UserLogs=null
              this.LicenseDetailsAll=null
              this.sortedGroup =null
              this._userpersona.globalUserid=null
              this._userpersona.globalUserEmail=null

              this._userpersona.globalUsername=null

              this._userpersona.oktaUserInfo=null
              this.zoomphone=null

              // console.log(this._userpersona.servicenowUserInfo + 'sdafdsaf');
              Swal.fire({
                text: 'User Not Found. Kindly check the User ID',
                icon: 'error',
              });
            }
          },
          (error) => {
            this.userInfo = '';
            console.log('error');
          }
        );        

      } catch (e) {        

        console.log('catch error');
      }
    }
  }

  // get User Logs
  getUserLogs() {
    // console.log('userlog api');
    this.isOktaLogsLoading = true;
    //Get user logs okta using email - limit-5
    // console.log(this._userpersona.globalUserEmail + 'sample test');
    this._oktaService
      .getUserLogs(
        this._userpersona.globalUserEmail,
        this._userpersona.globalOktaAccessToken
      )
      .subscribe(
        (userLogs: any) => {
          if (userLogs.http_status_code == 200) {
            this.isOktaLogsLoading = false;
            this.UserLogs = userLogs.data;
            this._userpersona.globalUserLogs = userLogs.data;

            // console.log(
            //   'UserComponent ~ this.route.queryParams.subscribe ~ userLogs',
            //   this.UserLogs
            // );
          } else {
            this.UserLogs = null;
            this.isOktaLogsLoading = false;
          }
        },
        (error) => {
          this.isOktaLogsLoading = false;
          console.log('error', error);
        }
      );
  }

  // get User Profile Pic
  getUserProfilePic() {
    // console.log('getUserProfilePic api' + this._userpersona.globalUserEmail);
    this._userpersona
      .getUserProfilePic(this._userpersona.globalUserEmail)
      .subscribe(
        (res: any) => {
          // console.log('getUserProfilePic ~ res', res);
          if (res.http_status_code == 200) {
            this.UserProfilePic = res.data;
            this._userpersona.globalUserProfilePic = res.data;
          } else {
            this.UserProfilePic = null;
            this._userpersona.globalUserProfilePic = null;
          }
        },
        (error) => {
          console.log('error', error);
        }
      );
  }

    // get User information from AAD
    getUserAADinfo() {
      // console.log('getUserProfilePic api' + this._userpersona.globalUserEmail);
      this._userpersona
        .getUserAADinfo(this._userpersona.globalUsername)
        .subscribe(
          (res: any) => {
            // console.log('getUserProfilePic ~ res', res);
            if (res.http_status_code == 200) {
              this.aadUserinfo = res.data;
              this._userpersona.globalUserAAD = res.data;
            } else {
              this.UserProfilePic = null;
              this._userpersona.globalUserAAD = null;
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
    }


  // Match User Email with ServiceNow and Okta
  MatchUserEmail(snowEmail: any, oktaEmail: any) {
    // console.log(snowEmail, oktaEmail + 'Sample12334');

    if (snowEmail !== oktaEmail) {
      Swal.fire({
        text: 'Okta data mismatch detected . User Insight is unable to fetch the information for the tabs "Okta Info", "Group Membership", "Okta MFA."',
        icon: 'warning',
      });
    }
    if (oktaEmail == undefined) {
      Swal.fire({
        text: 'Users OKTA Account is neither deactivated nor available in Okta. User Insight may not be able to fetch the information for the tabs "Okta Info", "Group Membership", "Okta MFA"',
        icon: 'info',
      });
    }
  }

  // Open ALert details
  openAlertPopup(AlertContent: any, AlertModal: any) {
    this.AlertmodalContent = AlertContent;
    this.modalService.open(AlertModal, { size: 'xl', centered: true });
  }

  CustomerSatification(){
    this.starValue={
      "totalCount":0,
      "totalScore":0
     }
     this.starTotalCount=0;
     this.starScoreCount=0;
     this.finalStartRating;
    this._userpersona
    .customerSatisifaction(this._userpersona.globalUserid)
    .subscribe(
      (res: any) => {
      
        if (res.http_status_code == 200) {
         this.customerSatifaction = res.data;
         console.log("UserComponent ~ CustomerSatification ~ this.customerSatifaction", this.customerSatifaction)
          this._userpersona.globalCustomerSatifiaction = res.data;
          // starValue:any={
          //   "totalCount":0,
          //   "totalScore":0
          //  }
        let groupby=_.groupBy( this._userpersona.globalCustomerSatifiaction, 'normalized_value');
   console.log("FavoritesComponent ~ ngOnInit ~ groupby", groupby)
    this.chart5.key=Object.keys(groupby)


    console.log("FavoritesComponent ~ chartForCustomerSatifaction ~ this.chart5.key", this.chart5.key)
   for(let a of this.chart5.key){
      console.log(a+"dsafffffffffffffff")
      if(a==0){
      this.chart5data[0]=a
      this.starValue.totalCount=1*groupby[a].length
      this.starValue.totalScore=groupby[a].length
      this.starTotalCount=this.starTotalCount+this.starValue.totalCount
      this.starScoreCount=this.starScoreCount+this.starValue.totalScore
      
     
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==1.67){
      this.chart5data[1]=a
      this.starValue.totalCount=2*groupby[a].length
      this.starValue.totalScore=groupby[a].length
      this.starTotalCount=this.starTotalCount+this.starValue.totalCount
      this.starScoreCount=this.starScoreCount+this.starValue.totalScore
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==3.33){
      
      this.chart5data[2]=a
      this. starValue.totalCount=3*groupby[a].length
      this.starValue.totalScore=groupby[a].length
      this.starTotalCount=this.starTotalCount+this.starValue.totalCount
      this.starScoreCount=this.starScoreCount+this.starValue.totalScore
      this.chart5.value.push(groupby[a].length)
      }
      else if(a==5){
      this.chart5data[3]=a
      console.log("UserComponent ~ CustomerSatification ~ this.chart5data[3]", this.chart5data[3])
      this.starValue.totalCount=4*groupby[a].length
      this.starValue.totalScore=groupby[a].length
      this.starTotalCount=this.starTotalCount+this.starValue.totalCount
      this.starScoreCount=this.starScoreCount+this.starValue.totalScore

      this.chart5.value.push(groupby[a].length)
      }
      }
      this.finalStartRating=this.starTotalCount/this.starScoreCount
      if(this.finalStartRating>0){
      this.finalStartRating=(this.finalStartRating* 90) / 4;
       
        this.isRating=true;
        this._userpersona.finalStartRating=this.finalStartRating
      }
      else{
        console.log("false")
        this.isRating=false;
        this.finalStartRating=0
        this._userpersona.finalStartRating=this.finalStartRating
      }
     
        } else {

        }
      },
      (error) => {
        console.log('error', error);
      }
    );

}
}
