<div class="container mt-4" *ngIf="!isMFALoading">
  <div class="row">
    <div class="col-6" *ngIf="mfaOptions.PhoneCall.isEnabled">
      <mat-card class="example-card">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <h2>Phone Call Code</h2>
          </div>
          <div
            *ngIf="
              !mfaOptions.PhoneCall.isSend && !mfaOptions.PhoneCall.isVerified
            "
          >
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="sendOTP({ mfaType: 'call' })"
            >
              Send
            </button>
          </div>
          <div
            *ngIf="
              mfaOptions.PhoneCall.isSend && !mfaOptions.PhoneCall.isVerified
            "
          >
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="resendOTP({ mfaType: 'call' })"
            >
              Resend
            </button>
          </div>
        </div>
        <mat-card-content>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input
                matInput               
                placeholder="+91xxxxxxxxx"
                disabled
                value="{{ phonenumberWithspecialCharacter ? phonenumberWithspecialCharacter : notAvailable }}"
              />
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
            <div class="container-fluid">
              <!-- <form (ngSubmit)="onSubmit()" formGroup="form"> -->
              <div class="row" style="justify-content: space-between">
                <div
                  class="form-group col-sm-2"
                  *ngFor="let phoneInput of PhoneformInput; index as i"
                >
                  <input
                    #PhoneformRow
                    type="text"
                    (formControlName)="(phoneInput)"
                    class="form-control"
                    maxlength="1"
                    (keyup)="keyUpEvent('phone', $event, i)"
                    (change)="changeInputValues('Phone', phoneInput, i, $event)"
                  />
                </div>
              </div>
              <!-- <button type="submit" (click)="onSubmit()" class="btn btn-primary">Submit</button> -->
              <!-- </form> -->
            </div>
            <div style="text-align: center">
              <div
                *ngIf="
                  mfaOptions.PhoneCall.isVerified;
                  then other_content;
                  else content
                "
              ></div>
              <ng-template #content>
                  <div style="    display: flex;
                      align-items: center;
                      justify-content: space-evenly;">
                        <button class="btn btn-outline-primary waves-effect" style="width: 25%" (click)="resetInputs('call')">
                          Reset
                        </button>
                <button
                  class="btn btn-primary waves-effect"
                  style="width: 25%"
                  (click)="OnVerify('call')"
                >
                  Verify
                </button>
              
                  </div>
              </ng-template>

              <ng-template #other_content>
                <i class="material-icons" style="color: green; font-size: 50px"
                  >verified</i
                >
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-6" *ngIf="mfaOptions.Sms.isEnabled">
      <mat-card class="example-card">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <h2>SMS Text Code</h2>
          </div>
          <div *ngIf="!mfaOptions.Sms.isSend && !mfaOptions.Sms.isVerified">
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="sendOTP({ mfaType: 'sms' })"
            >
              Send
            </button>
          </div>
          <div *ngIf="mfaOptions.Sms.isSend && !mfaOptions.Sms.isVerified">
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="resendOTP({ mfaType: 'sms' })"
            >
              Resend
            </button>
          </div>
        </div>
        <mat-card-content>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input
                matInput
                placeholder="+91xxxxxxxxx"
                disabled
                value="{{ phonenumberWithspecialCharacter ? phonenumberWithspecialCharacter : notAvailable }}"
              />
            </mat-form-field>
            <div class="container-fluid">
              <!-- <form (ngSubmit)="onSubmit()" formGroup="form"> -->
              <div class="row">
                <div
                  class="form-group col-sm-2"
                  *ngFor="let input of SMSformInput; index as i"
                >
                  <input
                    #SmsformRow
                    type="text"
                    (formControlName)="(input)"
                    class="form-control"
                    maxlength="1"
                    value=''
                    (keyup)="keyUpEvent('sms', $event, i)"
                    (change)="changeInputValues('Sms', input, i, $event)"
                  />
                </div>
              </div>
              <!-- <button type="submit" (click)="onSubmit()" class="btn btn-primary">Submit</button> -->
              <!-- </form> -->
            </div>

            <!-- </mat-form-field> -->
            <div style="text-align: center">
              <div
                *ngIf="
                  mfaOptions.Sms.isVerified;
                  then other_content;
                  else content
                "
              ></div>
              <ng-template #content>
                  <div style="    display: flex;
                      align-items: center;
                      justify-content: space-evenly;">
                        <button class="btn btn-outline-primary waves-effect" style="width: 25%" (click)="resetInputs('sms')">
                          Reset
                        </button>
                <button
                  class="btn btn-primary waves-effect"
                  style="width: 25%"
                  (click)="OnVerify('sms')"
                >
                  Verify
                </button>
              
                </div>
              </ng-template>

              <ng-template #other_content>
                <i class="material-icons" style="color: green; font-size: 50px"
                  >verified</i
                >
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6" *ngIf="mfaOptions.OktaVerify.isEnabled">
      <mat-card class="example-card">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <h2>Okta Verify Mobile App</h2>
          </div>
          <div
            *ngIf="
              !mfaOptions.OktaVerify.isSend && !mfaOptions.OktaVerify.isVerified
            "
          >
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="sendOTP({ mfaType: 'oktaMobPush' })"
            >
              Push
            </button>
          </div>
          <div
            *ngIf="
              mfaOptions.OktaVerify.isSend && !mfaOptions.OktaVerify.isVerified
            "
          >
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="validatePushNotification()"
            >
              Validate Push
            </button>
          </div>
        </div>
        <mat-card-content>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div class="container-fluid mt-2">
              <!-- <form (ngSubmit)="onSubmit()" formGroup="form"> -->
              <div class="row">
                <div
                  class="form-group col-sm-2"
                  *ngFor="let oktamobInput of OktaMobformInput; index as i"
                >
                  <input
                    #OktaMobformRow
                    type="text"
                    (formControlName)="(oktamobInput)"
                    class="form-control"
                    maxlength="1"
                    (keyup)="keyUpEvent('oktamobverify', $event, i)"
                    (change)="
                      changeInputValues('OktaVerify', oktamobInput, i, $event)
                    "
                  />
                </div>
              </div>
              <!-- <button type="submit" (click)="onSubmit()" class="btn btn-primary">Submit</button> -->
              <!-- </form> -->
            </div>
            <div style="text-align: center">
              <div
                *ngIf="
                  mfaOptions.OktaVerify.isVerified;
                  then other_content;
                  else content
                "
              ></div>
              <ng-template #content>
                <div style="    display: flex;
    align-items: center;
    justify-content: space-evenly;">
      <button class="btn btn-outline-primary waves-effect" style="width: 25%" (click)="resetInputs('oktaMobTotp')">
        Reset
      </button>
                  <button
                    class="btn btn-primary waves-effect"
                    style="width: 25%"
                    (click)="OnVerify('oktaMobTotp')"
                  >
                    Verify
                  </button>
                        

                </div>
              </ng-template>

              <ng-template #other_content>
                <i class="material-icons" style="color: green; font-size: 50px"
                  >verified</i
                >
              </ng-template>
              <!--             
              <button class="btn btn-primary waves-effect" style="width: 25%" (click)="OnVerify('oktaMobTotp')">
                {{ mfaOptions.OktaVerify.isVerified ? 'Verified' : 'Verify'}}
              </button> -->
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- <div class="col-6" *ngIf="mfaOptions.TempPassword.isEnabled">
      <mat-card class="example-card">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <h2>Temporary Password</h2>
          </div>
          <div>
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="generateTempPassword()"
            >
              Generate
            </button>
          </div>
        </div>
        <mat-card-content>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div class="container-fluid mt-2">              
              <div class="row">
                <div class="form-group w-100">
                  <input
                    type="text"
                    value="{{ mfaOptions.TempPassword.defaultValue }}"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>            
            </div>
            <div style="text-align: center">
              <div
                *ngIf="
                  mfaOptions.TempPassword.isVerified;
                  then other_content;
                  else content
                "
              ></div>
              <ng-template #content>
                <button
                  class="btn btn-primary waves-effect"
                  style="width: 25%"
                  [cdkCopyToClipboard]="mfaOptions.TempPassword.defaultValue"
                >
                  Copy
                </button>
              </ng-template>

              <ng-template #other_content>
                <i class="material-icons" style="color: green; font-size: 50px"
                  >verified</i
                >
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div> -->
  </div>
</div>

<div class="container mt-4" *ngIf="isMFALoading">
  <div
    class="text-center flex flex-column justify-content-center align-items-center"
  >
    <div>
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
    <div style="padding-top: 3%">
      <strong>Please Wait ... </strong>
    </div>
  </div>
</div>

<div
  class="container mt-4"
  *ngIf="
    !isMFALoading &&
    !mfaOptions.PhoneCall.isEnabled &&
    !mfaOptions.Sms.isEnabled &&
    !mfaOptions.OktaVerify.isEnabled
  "
>
  <div
    class="text-center flex flex-column justify-content-center align-items-center mt-10"
  >
    <div>
      <mat-icon style="transform: scale(1)">info</mat-icon>
    </div>
    <div style="padding-top: 1%">
      <strong>No MFA Found</strong>
    </div>
  </div>
</div>

<!-- Security Question -->
<!-- <div class="container mt-4" *ngIf="mfaOptions.TempPassword.isEnabled">
  <div class="row">
    <div class="col-12">
      <mat-card class="example-card">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <h2>Temporary Password</h2>
          </div>
          <div>
            <button
              class="btn btn-outline-primary waves-effect"
              (click)="generateTempPassword()"
            >
              Generate
            </button>
          </div>
        </div>
        <mat-card-content>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div class="container-fluid mt-2">
              <div class="row">
                <div>
                  A temporary password is created for the account and shown to
                  you. The account is then marked as expired so that the user is
                  required to change their password the next time they sign in.
                </div>
                <div class="form-group w-100 mt-2 flex">
                  <input
                    type="text"
                    value="{{ mfaOptions.TempPassword.defaultValue }}"
                    class="form-control"
                    disabled
                  />
                     <button
                  class="btn btn-primary waves-effect"
                  style="width: 13%"
                  [cdkCopyToClipboard]="mfaOptions.TempPassword.defaultValue"
                  (click)="handleTemporaryPasswordCopy()"
                >
                  Copy
                </button>    
                </div>
              </div>           
            </div>        
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div> -->
