<div
  class="logoImage"
  style="position: absolute; z-index: 1; left: -12px; padding: 15px"
>
  <img class="image" src="../../../assets/kclogo.png" />
</div>
<div>
  <mat-tab-group mat-align-tabs="center" class="maintab">
    <mat-tab label="User">
      <<ng-template mat-tab-label>
        <mat-icon>people</mat-icon>
        <span class="title iconwithtitle">User</span>
      </ng-template>
      <ng-template matTabContent>
        <app-user></app-user>
      </ng-template>
    </mat-tab>

    <!-- <mat-tab label="Device">
      <ng-template matTabContent>
        <app-device></app-device>
      </ng-template>
    </mat-tab> -->

    <mat-tab label="Device">
      <<ng-template mat-tab-label>
        <mat-icon>devices</mat-icon>
        <span class="title iconwithtitle">Devices</span>
      </ng-template>
      <ng-template matTabContent>
        <app-device></app-device>
      </ng-template>
    </mat-tab>

    <mat-tab label="Support">
      <<ng-template mat-tab-label>
        <mat-icon>support</mat-icon>
        <span class="title iconwithtitle">Support</span>
      </ng-template>
      <ng-template matTabContent>
        <app-support></app-support>
      </ng-template>
    </mat-tab>

    <mat-tab label="Okta Support">
      <<ng-template mat-tab-label>
        <mat-icon>important_devices</mat-icon>
        <span class="title iconwithtitle">OKTA MFA</span>
      </ng-template>
      <ng-template matTabContent>
        <app-okta-mfa></app-okta-mfa>
      </ng-template>
    </mat-tab>

    <mat-tab label="Favorites">
      <ng-template mat-tab-label>
        <mat-icon>collections_bookmark</mat-icon>
        <span class="title iconwithtitle">Favorites</span>
      </ng-template>
      <ng-template matTabContent>
       <app-favorites></app-favorites>
      </ng-template>
    </mat-tab>

    <!-- <mat-tab label="Support" >
      <ng-template matTabContent>
        <app-support></app-support>
      </ng-template>
    </mat-tab> -->

    <!-- <mat-tab label="VA Details  ">
      <ng-template matTabContent>
        <app-vadetails></app-vadetails>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</div>
