import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrl } from '../baseUrl';
const options: any = {
  Sms: {
    isSend: false,
    isVerified: false,
    title: 'sms',
    isloading: false,
    isEnabled: false,
    factorId: '',
  },
  PhoneCall: {
    isSend: false,
    isVerified: false,
    title: 'call',
    isloading: false,
    isEnabled: false,
    factorId: '',
  },
  OktaVerify: {
    isSend: false,
    isVerified: false,
    title: 'okta verify',
    isloading: false,
    isEnabled: false,
    push: {
      factorId: '',
      transactionId: '',
    },
    totp: {
      factorId: '',
    },
  },
  // TempPassword: {
  //   isSend: false,
  //   isGenerated: false,
  //   defaultValue: 'Click Generate',
  //   title: 'GenerateTempPassword',
  //   isloading: false,
  //   isEnabled: false,
  // },
};
@Injectable({
  providedIn: 'root',
})
export class OktaService {
  allFactors: any = {};
  mfaOptions: any = options;

  constructor(private http: HttpClient) {}

  public getUserInfoFromOkta(email: string, accessToken: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'getUserDetailsoktaAT',
      payload: {
        email: email,
        accessToken: accessToken,
      },
    };
    console.log(data);

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public getUserGroupFromOkta(userId: string, accessToken: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'getUserGroupbyUseridAT',
      payload: {
        userId: userId,
        accessToken:accessToken
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public getUserMFA(userId: string, accessToken: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'getUserListofMFAusingAT',
      payload: {
        userId: userId,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public sendFactorbyFactorid(
    userId: string,
    factorId: string,
    accessToken: string
  ) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'sendFactorbyFactoridAT',
      payload: {
        userId: userId,
        factorId: factorId,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public verifyFactorbyFactorid(
    userId: string,
    factorId: string,
    passCode: string,
    accessToken: string
  ) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'verifyFactorbyFactoridAT',
      payload: {
        userId: userId,
        factorId: factorId,
        passCode: passCode,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public validateOktaMobilePush(
    userId: string,
    factorId: string,
    transactionId: string,
    accessToken: string
  ) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'verifyOktaPushAT',
      payload: {
        userId: userId,
        factorId: factorId,
        transactionId: transactionId,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public TriggerTemporaryPassword(userId: string, accessToken: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'tempPasswordAT',
      payload: {
        userId: userId,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public getUserLogs(email: string, accessToken: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let data = {
      action: 'userLogsAT',
      payload: {
        userId: email,
        limit: 5,
        accessToken: accessToken,
      },
    };

    return this.http.post(BaseUrl + 'esi/okta', data, options);
  }

  public ResetOktaMFA() {
    this.allFactors = {};
    this.mfaOptions = options;
  }
}
