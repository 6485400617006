import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrl } from '../baseUrl';
@Injectable({
  providedIn: 'root',
})
export class SupportpersonaService {

  serviceRequestDatas: any=null;
  RequestItemDatas: any=null;
  TaskDatas: any=null;
  ticketDatas: any=null;
  
  constructor(private http: HttpClient) {}

  public getUserIncidentDetails(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'Last90daysIncident',
      payload: {
        sys_id: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public searchIncidentTicket(TicketNumber: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'SearchIncidentTicket',
      payload: {
        incidentNumber: TicketNumber,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public getUserServiceRequestDetails(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'Last90daysServiceRequest',
      payload: {
        sys_id: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public searchServiceRequest(RequestNumber: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'SearchServiceRequest',
      payload: {
        requestNumber: RequestNumber,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public getUserRequestItemDetails(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'UserRequestItembySysid',
      payload: {
        sysid: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public searchRequestItem(itemNumber: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'SearchRequestItem',
      payload: {
        itemNumber: itemNumber,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public getUserTasks(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'Last90daysTasks',
      payload: {
        sys_id: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
  public searchUserTasks(RequestNumber: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'SearchUserTask',
      payload: {
        taskNumber: RequestNumber,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }


  public Last90daysIncident(sysid: string) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let data = {
      action: 'Last90daysIncident',
      payload: {
        sys_id: sysid,
      },
    };

    return this.http.post(BaseUrl + 'esi/itsm', data, options);
  }
}
