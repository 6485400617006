<div class="pagealign">
  <mat-tab-group mat-stretch-tabs class="streached">
    <mat-tab label="Asset Information">
      <div>
        <div class="row">
          <!-- left div in device -->
          <div class="col-5">
            <div class="mt-2">
              <mat-card style="border: 1px solid black">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <mat-card-title>Device</mat-card-title>
                  <!-- <h3>Device</h3> -->
                  <mat-form-field appearance="fill">
                    <mat-label>{{ SelectedDeviceLabel }}</mat-label>
                    <mat-select (selectionChange)="selectOption($event.value)">
                      <mat-option
                        *ngFor="let device of assetDetails"
                        [value]="device"
                      >
                        {{ device?.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-card>
            </div>

            <div class="mt-2">
              <mat-card class="mat-elevation-z0 card-background">
                <!-- <mat-card-title class="cm-text-color ">Hardware</mat-card-title> -->
                <!-- <p class="cm-text-color ">Hardware</p> -->
                <p class="title-white">Operating System</p>
                <p class="borderbottom"></p>
                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">Operating System</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{ asset?.os ? asset?.os : notAvailableMsg }}
                    </p>
                  </div>
                </div>
                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">OS Version</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.os_version ? asset?.os_version : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>
                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">OS Service Pack</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.os_service_pack
                          ? asset?.os_service_pack
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>
                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">Disk space(GB)</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.disk_space ? asset?.disk_space : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>
                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">RAM (MB)</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{ asset?.ram ? asset?.ram : notAvailableMsg }}
                    </p>
                  </div>
                </div>

                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">Short Description</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.short_description
                          ? asset?.short_description
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>

                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">64Bit Capable</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.u_is_64bit_capable
                          ? asset?.u_is_64bit_capable
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>


                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">IP address</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{ asset?.ip_address ? asset?.ip_address : notAvailableMsg }}
                    </p>
                  </div>
                </div>


                <div class="row m-3">
                  <div class="col-sm-6">
                    <p class="text-white">MAC address</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{ asset?.mac_address ? asset?.mac_address : notAvailableMsg }}
                    </p>
                  </div>
                </div>
              </mat-card>
            </div>

           
          </div>

          <!-- Right div in device -->
          <div class="col-7">
            <div class="mt-2">
              <!-- <mat-card class="card-clr mat-elevation-z0"> -->
              <mat-card class="card mat-elevation-z0 card-background">
                <!-- <p class="cm-text-color ">Hardware</p> -->
                <div class="flex" style="justify-content: space-between">
                  <div class="flex">
                    <i class="material-icons" style="color: white"
                      >desktop_mac</i
                    >
                    <p class="title-white ml-2">Asset</p>
                  </div>
                  <div style="cursor: pointer" [cdkCopyToClipboard]="CopyText">
                    <i class="material-icons" style="color: white">file_copy</i>
                  </div>
                </div>
                <p class="borderbottom"></p>
                <div class="row m-4">
                  <div class="col-sm-6">
                    <p class="key-text-white">Asset Name</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{ asset?.name ? asset?.name : notAvailableMsg }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Asset Status</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.install_status
                          ? asset?.install_status
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <p class="key-text-white">Model</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{ asset?.model_id ? asset?.model_id : notAvailableMsg }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Serial Number</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.serial_number
                          ? asset?.serial_number
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Manufacturer</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.manufacturer
                          ? asset?.manufacturer
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Form Factor</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.form_factor
                          ? asset?.form_factor
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">KC Build Type</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.u_kc_build_type
                          ? asset?.u_kc_build_type
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Assigned To</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.assigned_to
                          ? asset?.assigned_to
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Last Communicated Date</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.u_last_communicated_date
                          ? asset?.u_last_communicated_date
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Asset Refresh Date</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{ CurrentAssetRefreshDate ? CurrentAssetRefreshDate : notAvailableMsg }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Asset Warranty Expiration Date</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.warranty_expiration
                          ? asset?.warranty_expiration
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Support Group</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.support_group
                          ? asset?.support_group
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Last Logged On User</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.u_last_logged_on_user
                          ? asset?.u_last_logged_on_user
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="key-text-white">Last Authenticated Location</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="value-text-white">
                      {{
                        asset?.u_last_authenticated_location
                          ? asset?.u_last_authenticated_location
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="text-white">Asset Created On</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.sys_created_on
                          ? asset?.sys_created_on
                          : notAvailableMsg
                      }}
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="text-white">Last SCCM Feed Date</p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-white">
                      {{
                        asset?.u_last_sccm_feed_date
                          ? asset?.u_last_sccm_feed_date
                          : notAvailableMsg
                      }}
                    </p>
                  </div>
                </div>
              </mat-card>
              <!-- </mat-card> -->
            </div>
          </div>

          <div class="col-12 mt-2">
            <mat-card style="border: 1px solid grey;background: #7e8185;" >
              <mat-card-title style="color: white">Applications Installed</mat-card-title>
            <mat-form-field>
              <mat-label style="color: white">Search Apps</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="MS word" #input >
            </mat-form-field>
            
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
            
                <!-- ID Column -->
                <ng-container matColumnDef="display_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Software Name </th>
                  <td mat-cell *matCellDef="let row"> {{row.display_name}} </td>
                </ng-container>
            
                <!-- Progress Column -->
                <ng-container matColumnDef="publisher">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Publisher </th>
                  <td mat-cell *matCellDef="let row"> {{row.publisher}} </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="version">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                  <td mat-cell *matCellDef="let row"> {{row.version}} </td>
                </ng-container>
            
                <!-- Color Column -->
                <ng-container matColumnDef="install_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Installed Date </th>
                  <td mat-cell *matCellDef="let row"> {{row.install_date}} </td>
                </ng-container>

                <ng-container matColumnDef="last_scanned">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Scanned Date </th>
                  <td mat-cell *matCellDef="let row"> {{row.last_scanned}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
            
            
          </mat-card>
          </div>
        
        </div>
      </div>
    </mat-tab>

    <mat-tab label="MAM Devices">
      <div>
        <div class="row">
         
          <div class="col-6 mt-2" *ngFor="let device of ownedDeviceDetailsofUser">
            <mat-card class="mat-elevation-z0 card-background">
              <!-- <mat-card-title class="cm-text-color ">Hardware</mat-card-title> -->
              <!-- <p class="cm-text-color ">Hardware</p> -->
              <p class="title-white mx-4" style="display: flex;">
                <span *ngIf="device?.operatingSystem=='Windows'" style="margin-right: 5px"><i class="material-icons">laptop_windows</i></span>
                <span *ngIf="device?.operatingSystem=='Android'" style="margin-right: 5px"><mat-icon>android</mat-icon></span>
                <span *ngIf="device?.operatingSystem=='iOS'" style="margin-right: 5px" ><i class="material-icons">apple</i></span>

                {{device?.displayName |  uppercase}}
              
              
              </p>
              <p class="borderbottom"></p>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'Profile type' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.profileType?device?.profileType:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'display Name' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.displayName?device?.displayName:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'device Ownership' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.deviceOwnership?device?.deviceOwnership:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'isCompliant' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.isCompliant?device?.isCompliant:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'isManaged' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.isManaged?device?.isManaged:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'enrollmentProfileName' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.enrollmentProfileName?device?.enrollmentProfileName:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'enrollmentType' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.enrollmentType?device?.enrollmentType:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'managementType' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.managementType?device?.managementType:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'operatingSystem' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.operatingSystem?device?.operatingSystem:notAvailableMsg}}</p>
                </div>
              </div>
              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white">{{'operatingSystemVersion' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.operatingSystemVersion?device?.operatingSystemVersion:notAvailableMsg}}</p>

                </div>
              </div>

              <div class="row mx-4">
                <div class="col-sm-6">
                  <p class="text-white"> {{'Last SignIn DateTime' | titlecase}}</p>
                </div>
                <div class="col-sm-6">
                  <p class="text-white">{{device?.approximateLastSignInDateTime?device?.approximateLastSignInDateTime:notAvailableMsg}}</p>

                </div>
              </div>
            </mat-card>
          </div>
        
        </div>
           <div
           style="margin-top: 5%;"
                class="text-center flex flex-column justify-content-center align-items-center "
                *ngIf="ownedDeviceDetailsofUser?.length ===0"
              >
                <div>
                  <!-- <i class="fa fa-exclamation-triangle "  aria-hidden="true" ></i> -->
                  <mat-icon style="transform: scale(1)">info</mat-icon>
                </div>
                <div style="padding-top: 2%">
                  <strong>No MAM Device Found</strong>
                </div>
              </div>
        
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <mat-tab-group mat-stretch-tabs class="streached">
    <mat-tab label="Details">
     
        <mat-grid-list cols="2" rowHeight="1:1">
            <mat-grid-tile [colspan]="1"
            [rowspan]="2" style="margin: 5px;border: 2px solid black;" >
        
            <mat-card class="example-card">
                <mat-card-title-group>
                  <mat-card-title>Shiba Inu</mat-card-title>
                  <mat-card-subtitle>Small</mat-card-subtitle>
                  <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >
                </mat-card-title-group>
                <mat-card-content>
                 
                </mat-card-content>
              </mat-card>
        
        
        </mat-grid-tile>
            <mat-grid-tile [colspan]="1"
            [rowspan]="2">world</mat-grid-tile>
           
          </mat-grid-list>
     
    </mat-tab>

    <mat-tab label="Preferences"> 
      <div class="contentdiv">


      </div>
    </mat-tab>

    <mat-tab label=" Group Membership"> Group Membership </mat-tab>
  </mat-tab-group> -->
